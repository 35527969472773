<!--
 * @Author: DQL
 * @Date: 2022-11-30 10:13:40
 * @LastEditors: zxf
 * @LastEditTime: 2023-10-19 16:44:20
 * @Description: file content
-->
<template>
  <div
    class="dev-o-echart"
  >
    <div
      v-if="scData.length > 0"
      class="dev-sc"
    >
      <swiper
        class="swiper"
        circular
        :indicator-dots="indicatorDots"
        :autoplay="autoplay"
        :interval="interval"
        :duration="duration"
      >
        <swiper-item>
          <div
            class="dev-tmsc"
          >
            <div class="dev-sc-tp_tm">
              <div
                style="margin-top: -38px;"
                class="dev-dq-rj"
              >
                {{ getScRj(scData[0],scData[0].sz) }}
              </div>
            </div>
            <div class="dev-sc-wbk">
              <div
                class="dev-sc-wb"
                :class="getYcStyleBg(scData[0])"
              >
                <div class="dev-title">
                  <span>{{ scData[0].azwz }} ：{{ scData[0].sz }}m</span>
                  <span
                    style="margin-left:7px"
                    :class="getYcTestColor(scData[0])"
                  >{{ getScTs(scData[0]) }}</span>
                </div>
                <div style="display: flex;font-size: 13px;">
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 高：{{ scData[0].height?scData[0].height+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 长：{{ scData[0].length?scData[0].length+'m':'' }}</span>
                    </div>
                  </div>
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 宽：{{ scData[0].width?scData[0].width+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 容积：{{ getScRj(scData[0]) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dev-dxsc" />
            </div>
          </div>
          <div
            v-if="scData.length > 1"
            class="dev-dxsc"
          >
            <div class="dev-sc-wbk_dx">
              <div
                class="dev-sc-wb_dx"
                :class="getYcStyleBg(scData[1])"
              >
                <div class="dev-title">
                  <span>{{ scData[1].azwz }} ：{{ scData[1].sz }}m</span>
                  <span
                    style="margin-left:7px"
                    :class="getYcTestColor(scData[1])"
                  >{{ getScTs(scData[1]) }}</span>
                </div>
                <div style="display: flex;font-size: 13px;">
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 高：{{ scData[1].height?scData[1].height+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 长：{{ scData[1].length?scData[1].length+'m':'' }}</span>
                    </div>
                  </div>
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 宽：{{ scData[1].width?scData[1].width+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 容积：{{ getScRj(scData[1]) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-sc-tp_dx">
              <div class="dev-dq-rj">
                {{ getScRj(scData[1],scData[1].sz) }}
              </div>
            </div>
          </div>
        </swiper-item>
        <!-- <swiper-item
          v-if="scData.length >= 3"
        >
          <div
            class="dev-tmsc"
            :class="{'dev-jusc-tm': scData.length === 3 }"
          >
            <div class="dev-sc-tp_tm">
              <div
                style="margin-top: -38px;"
                class="dev-dq-rj"
              >
                {{ getScRj(scData[2],scData[2].sz) }}
              </div>
            </div>
            <div class="dev-sc-wbk">
              <div
                class="dev-sc-wb"
                :class="getYcStyleBg(scData[2])"
              >
                <div class="dev-title">
                  <span>{{ scData[2].azwz }} ：{{ scData[2].sz }}m</span>
                  <span
                    style="margin-left:7px"
                    :class="getYcTestColor(scData[2])"
                  >{{ getScTs(scData[2]) }}</span>
                </div>
                <div style="display: flex;">
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 高：{{ scData[2].height?scData[2].height+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 长：{{ scData[2].length?scData[2].length+'m':'' }}</span>
                    </div>
                  </div>
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 宽：{{ scData[2].width?scData[2].width+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 容积：{{ getScRj(scData[2]) }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="dev-dxsc" />
            </div>
          </div>
          <div
            v-if="scData.length >= 4"
            class="dev-dxsc"
          >
            <div class="dev-sc-wbk_dx">
              <div
                class="dev-sc-wb_dx"
                :class="getYcStyleBg(scData[3])"
              >
                <div class="dev-title">
                  <span>{{ scData[3].azwz }} ：{{ scData[3].sz }}m</span>
                  <span
                    style="margin-left:7px"
                    :class="getYcTestColor(scData[3])"
                  >{{ getScTs(scData[3]) }}</span>
                </div>
                <div style="display: flex;">
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 高：{{ scData[3].height?scData[3].height+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 长：{{ scData[3].length?scData[3].length+'m':'' }}</span>
                    </div>
                  </div>
                  <div class="dev-sbpj">
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 宽：{{ scData[3].width?scData[3].width+'m':'' }}</span>
                    </div>
                    <div class="dev-sc-rj">
                      <image
                        class="dev-sc-jz"
                        src="/monitor/images/tj/水位建筑.png"
                      />
                      <span> 容积：{{ getScRj(scData[3]) }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dev-sc-tp_dx">
              <div class="dev-dq-rj">
                {{ getScRj(scData[3],scData[3].sz) }}
              </div>
            </div>
          </div>
        </swiper-item> -->
      </swiper>
    </div>
    <div
      v-if="chartData.length===0 || scData.length === 0 "
      class="dev-o-echart__empty"
    >
      暂无记录！
    </div>
  </div>
</template>

<script>

// import 'echarts-liquidfill';
export default {
  name: 'SwChart',
  props: {
    chartData: {
      required: false,
      type: Array,
      default: function () {
        return [];
      }
    }
  },
  data () {
    return {
      vueChart: null,

      scData: [],
      indicatorDots: true,
      autoplay: true,
      interval: 7000,
      duration: 2000

    };
  },
  methods: {
    changeIndicatorDots (e) {
      this.indicatorDots = !this.indicatorDots;
    },
    changeAutoplay (e) {
      this.autoplay = !this.autoplay;
    },
    intervalChange (e) {
      this.interval = e.target.value;
    },
    durationChange (e) {
      this.duration = e.target.value;
    },
    // 异常状态背景框
    getYcStyleBg (item) {
      var sts = '';
      if (item.bjzt === '10') {
        sts = 'dev-o-yc__item--hj';
      } else if (item.bjzt === '20') {
        sts = 'dev-o-yc__item--gz';
      } else if (item.ztms.indexOf('高位') > -1 || item.ztms.indexOf('低位') > -1) {
        sts = 'dev-o-yc__item--gj';
      }

      return sts;
    },
    // 异常状态提示文本颜色
    getYcTestColor (item) {
      var sts = '';
      if (item.bjzt === '10') {
        sts = 'dev-o-yc__test--hj';
      } else if (item.bjzt === '20') {
        sts = 'dev-o-yc__test--gz';
      } else if (item.bjztZl === '01_GW' || item.bjztZl === '01_DW') {
        sts = 'dev-o-yc__test--gj';
      }

      return sts;
    },

    // 获取水池提示信息
    getScTs (_item) {
      var ts = '';
      if (_item.bjzt === '20') {
        ts = '故障';
      } else if (_item.bjztZl === '01_GW') {
        ts = '高位';
      } else if (_item.bjztZl === '01_DW') {
        ts = '低位';
      }
      return ts;
    },
    // 获取水池的容积
    getScRj (_item, sz) {
      if (_item.width && _item.height && _item.length) {
        return ((sz || _item.height) * _item.width * _item.length).toFixed(2) + 'm³(吨)';
      }
    },

    // 过滤按照 "azwz" 分组的数据，找到 "cjsj" 最新的对象，并计算百分比
    getLatestDataByGroup (data) {
      // 创建一个映射，键是 "azwz"，值是对象数组
      const groups = data.reduce((map, item) => {
        const key = `${item.bjqh}-${item.bjhlh}-${item.bjwh}`;
        if (!map[key]) {
          map[key] = [];
        }
        map[key].push(item);
        return map;
      }, {});

      // 对于每个分组，找到 "cjsj" 最新的对象，并计算百分比
      const result = [];
      for (const key in groups) {
        const group = groups[key];
        group.sort((a, b) => b.cjsj - a.cjsj); // 按 "cjsj" 降序排序
        const latest = group[0]; // 取 "cjsj" 最新的对象
        // 计算百分比
        console.log('latest', latest.sz);
        const height = latest.height ? latest.height : 10;
        const value = ((parseFloat(latest.sz) / parseFloat(height))).toFixed(2); // 计算百分比并保留两位小数
        latest.value = value;

        result.push(latest);
      }

      this.scData = result;
      console.log('scData', this.scData);
    }

  },
  watch: {
    chartData: function (nVal, oVal) {
      // this.getScSz();
      this.getLatestDataByGroup(nVal);
    }
  },
  mounted () {
    console.log('chartDat', this.chartData);
    this.getLatestDataByGroup(this.chartData);
  },
  beforeDestroy () {
    // window.removeEventListener('resize', this.handleResize);
  }
};
</script>
<style lang="less" scoped>
// @import './sgwYc.scss';

.dev-o-echart{
  min-height: 240px;
  &_title{
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-weight: 700;
    position: absolute;
    right: 0px;
    top: -53px;
    font-size: 11px;
    display: flex;
    color: #fff;
  }
}
.dev-dw{
    position: absolute;
    right:10px;
    top:-45px;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-weight: 700;
    font-size: 14px;
    color: #ffffffa3;
    letter-spacing: 0;
  }

  .dev-sc{
    height: 200px;
    padding:20px 0px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-weight: 400;
    font-size: 14px;
    color: #FFFFFF;

  }
  .dev-tmsc{
    display: flex;
    width: 100%;
    margin-top: 60px;
  }
  .dev-dxsc{
    display: flex;
    position: absolute;
    right: 0;
    bottom: 10px;
  }
  .dev-sc-tp_tm{
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 214px;

    height: 176px;
    background: url(/monitor/images/tj/天面水池.png);
    background-size: 100% 100%;
  }
  .dev-sc-tp_dx{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 246px;
    height: 185px;
    top: 150px;
    right: -25px;
    background: url(/monitor/images/tj/地下水池.png);
    background-size: 100% 100%;
  }
  .dev-sc-wbk{
    margin-left: -138px;
    position: relative;
    width: 330px;
    height: 100px;
    z-index: 1;
    background: url(/monitor/images/tj/天面.png);
    background-size: 100% 100%;
  }
  .dev-sc-wbk_dx{
    top: 254px;
    left: 156px;
    position: relative;
    width: 310px;
    height: 100px;
    z-index: 1;
    background: url(/monitor/images/tj/地下.png);
    background-size: 100% 100%;

  }
  .dev-sc-wb{
    position: absolute;
    width: 206px;
    right: 0px;
    line-height: 24px;
    padding: 10px 0px 6px 18px;
    border-radius: 15px 0px 14px 0px;

  }
  .dev-sc-wb_dx{
    position: absolute;
    width: 225px;
    line-height: 26px;
    padding: 10px 0px 3px 20px;
    border-radius: 14px 0 14px 0px;

  }

  .dev-title{

    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-weight: 700;
    font-size: 16px;
    color: #00CFFF;

  }
  .dev-sbpj{
    // width: 50%;

    align-items: center;
  }
  .dev-sc-jz{
    width: 15px;
    height: 15px;
    margin: 0 5px 0 10px;

  }

  .dev-sc-rj{
    display: flex;
    align-items: center;
  }

  .dev-jusc-tm{
    position: relative;
    left: 29%;
  }

  .dev-jusc-dx{
    position: relative;
    right:0px;
    top: -40px;
  }

  .dev-dq-rj{
    display: flex;
    height: 28px;
    position: fixed;
    align-items: center;
    font-size: 16px;
    border-radius: 4px;
    padding: 0 4px;
    margin-top: -38px;
    // background-color: #08193f;

  }

  .dev-gwgj{
    box-shadow: 0px 0px 10px 4px #f44336;
  //  color: #f44336;
  }

  .dev-dwgj{
     box-shadow: 0px 0px 10px 4px #ffd000;
  }

  .dev-fhm-cs
  {
    display: flex;
    align-items: center;
    &::before {
      content: "";
      display: inline-block;
      width: 22px;
      height: 13px;
      border-radius: 2px;
      margin: 0 8px;

}

  }

.dev-cs-1 {
  color: #ffd000;
  margin-left: 10px;
}
.dev-cs-2::before {
  background-color: #ffd000;
}

.swiper{
  height: 258px;
  top: -44px;
  position: relative;
}
.swiper {
  ::v-deep.uni-swiper-dots-horizontal{
    bottom: 0;
  }
  ::v-deep .uni-swiper-dot{
    background: rgb(108 109 110);
    width: 15px;
    height: 2px;
    border-radius: 5%;
  }

  ::v-deep .uni-swiper-dot-active{
    width: 15px;
    height: 2px;
    border-radius: 5%;
    background-color: #00d1ff;
  }

}

.dev-o-yc__test {
  &--hj {
    color: #e3131e;
  }

  &--gz {

    color: #f36709;
  }

  &--gj {
    color: #ebd80d;
  }

  &--cs {
    font-size: 12px !important;
    color: #9E9E9E;
  }
}
.dev-o-echart__empty{
  display: flex;
    margin: auto;
    /* text-align: center; */
    justify-content: center;
    align-items: center;
    line-height: 150px;
    font-size: 16px;
}
</style>
