<!--
 * @Author: DQL
 * @Date: 2022-11-30 10:13:40
 * @LastEditors: zxf
 * @LastEditTime: 2024-01-02 19:45:27
 * @Description: file content
-->
<template>
  <div class="dev-o-echart">
    <div
      class="div-body"
      style="width: 90%;height: 100%;overflow-x: hidden;"
    >
      <div
        style="margin-top: 45px;margin-left: -1px;"
        class="div-item_num"
      >
        <div
          class="div-item_num_test"
          style="margin-left: 40px;"
        >
          类别
        </div>
        <div
          class="div-item_num_test"
        >
          今日发生
        </div>
        <div
          class="div-item_num_test"
        >
          今日核查
        </div>
        <div

          class="div-item_num_test"
        >
          累计发生
        </div>

        <!-- <div class="div-item_num_test">
          累计核查
        </div> -->
      </div>
      <div
        v-for="(item,index) in dataList"
        :key="index"
        :class="bgStr+item.fxdjbm"
        class="div-body_item"
      >
        <div class="div-item_img">
          <img
            v-if="item.fxdjbm === 'tz'"
            style="width: 20px;height:20px;margin: 0 4px;"
            class="dev-fhm-img"
            src="/monitor/images/dqhz/tz.svg"
          >
          <img
            v-if="item.fxdjbm === 'one'"
            style="width: 20px;height:20px;margin: 0 4px;"
            class="dev-fhm-img"
            src="/monitor/images/dqhz/yj-1.svg"
          >
          <img
            v-if="item.fxdjbm === 'two'"
            style="width: 20px;height:20px;margin: 0 4px;"
            class="dev-fhm-img"
            src="/monitor/images/dqhz/yj-2.svg"
          >
          <img
            v-if="item.fxdjbm === 'three'"
            style="width: 20px;height:20px;margin: 0 4px;"
            class="dev-fhm-img"
            src="/monitor/images/dqhz/yj-3.svg"
          >
          <img
            v-if="item.fxdjbm === 'total'"
            style="width: 20px;height:20px;margin: 0 4px;"
            class="dev-fhm-img"
            src="/monitor/images/dqhz/yj-zs.svg"
          >
        </div>
        <div
          :class="bgStr+item.fxdjbm"
          style="line-height:24px"
        >
          {{ item.fxdjmc }}
        </div>
        <div
          class="div-item_num"
        >
          <div
            style="color: #3be4df;"
            class="div-item_num_test"
          >
            {{ item.jrfs }}
          </div>
          <div
            style="color: chartreuse;"
            class="div-item_num_test"
          >
            {{ item.jrhc }}
          </div>
          <div
            style="color: #3be4df;"
            class="div-item_num_test"
          >
            {{ item.ljfs }}
          </div>
          <!--
          <div
            style="color: chartreuse;"
            class="div-item_num_test"
          >
            {{ item.ljhc }}
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { monitorService } from '../monitor.service';

export default {
  name: 'Fxsstj',
  props: {
    // chartData: {
    //   required: false,
    //   type: Array,
    //   default: function () {
    //     return [];
    //   }
    // }
  },
  data () {
    return {
      clock: null,
      bgStr: 'div-fxdj-bg_',
      dataList: [
        {
          fxdjbm: 'tz',
          fxdjmc: '跳闸',
          jrfs: 0,
          jrhc: 0,
          ljfs: 0,
          ljhc: 0
        },
        {
          fxdjbm: 'one',
          fxdjmc: '一级',
          jrfs: 0,
          jrhc: 0,
          ljfs: 0,
          ljhc: 0
        },
        {
          fxdjbm: 'two',
          fxdjmc: '二级',
          jrfs: 0,
          jrhc: 0,
          ljfs: 0,
          ljhc: 0
        },
        {
          fxdjbm: 'three',
          fxdjmc: '三级',
          jrfs: 0,
          jrhc: 0,
          ljfs: 0,
          ljhc: 0
        },
        {
          fxdjbm: 'total',
          fxdjmc: '合计',
          jrfs: 0,
          jrhc: 0,
          ljfs: 0,
          ljhc: 0
        }
      ]
    };
  },

  methods: {

    /**
     * 获取风险实时统计信息
     */
    queryFxssTj () {
      const _this = this;
      monitorService.queryFxssTj()
        .then(res => {
          console.log('获取风险实时统计信息成功！', res.data);
          const data = res.data;
          data.forEach(e => {
            _this.dataList = _this.dataList.filter(item => {
              if (e.fxdjmc === item.fxdjmc) {
                item.jrfs = e.jrfs;
                item.jrhc = e.jrhc;
                item.ljfs = e.ljfs;
                item.ljhc = e.ljhc;
              }

              return item;
            });
            _this.dataList[4].jrfs = parseInt(_this.dataList[4].jrfs) + parseInt(e.jrfs);
            _this.dataList[4].jrhc = parseInt(_this.dataList[4].jrhc) + parseInt(e.jrhc);
            _this.dataList[4].ljfs = parseInt(_this.dataList[4].ljfs) + parseInt(e.ljfs);
            _this.dataList[4].ljhc = parseInt(_this.dataList[4].ljhc) + parseInt(e.ljhc);
          });
          // _this.dataList = res.data;
        })
        .catch(error => {
          console.log('获取风险实时统计信息失败！', error);
        })
        .finally(() => {

        });
    }

  },
  watch: {
    // chartData (newVal, oldVal) {
    //   if (newVal) {
    //     this.getServerData();
    //     this.getGwylTimeOut();
    //   }

  },
  created () {
        const _this = this;
        this.queryFxssTj();
        this.clock = setInterval(function () {
        _this.queryFxssTj();
        }, 1 * 60 * 1000);
    },
  destroyed () {
    clearInterval(this.clock);
  }
};
</script>

<style lang="less" scoped>
.div-body{
  margin-top: -40px;
    &_item{
      display: flex;
      width: 100%;
      height: 40px;
      background-color: #465474;
      align-items: center;
      border-radius: 20px 0 0 0;
      margin: 5px;
    }
}
.div-item_img{
  // width: 20px;
  // height: 20px;
  // background-color: aquamarine;
  margin: 0 20px;
}
.div-item_num{
  display: flex;
  width: 100%;
  margin-left: -150px;
  // margin-left: 35%;
  // text-align: center;
 &_test{
  // width: 25%;
  // margin: auto;
  // margin-left: 0;
  font-size: 16px;
  color: #fff;
  // margin-left: -50%;
  width: 33%;

 }
}
.div-fxdj-bg{
  // background-color: #20283b;
  // width: 10%;
  &_tz{
    background-color: #20283b;
    color: red;
    width: 100%;
    height: 24px;
  }
  &_one{
    background-color: #20283b;
   color: #f0751e;
   width: 100%;
   height: 24px;
  }
  &_two{
    background-color: #24383a;
    color: #fbe714;
    width: 100%;
    height: 24px;
  }
  &_three{
    background-color: #23413b;
    color: #2f5ef8;
    width: 100%;
    height: 24px;
  }
  &_total{
    background-color: #0a314e;
    color: #cdcdcd;
    width: 100%;
    height: 24px;
  }
}
</style>
