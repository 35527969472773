<template>
  <div>
    <div
      :class="introduction.jqrjks.style"
      class="dev-left-bg"
      style="padding-right: -15px;"
    >
      <div class="dev-title-jks">
        <span class="dev-title-jks_test">机器人监控室</span>
        <div
          class="dev-yjcg"
          type="primary"
          @click="handleYjcg()"
        >
          <img
            class="dev-cg-img"
            src="/monitor/images/tj/cg.svg"
          >
          <span>  一键查岗</span>
        </div>
      </div>

      <div class="monitor_center_box-body">
        <div class="dev-zt">
          <div
            class="dev-zt_zx"
            @click="lwzt='1'"
          >
            <dev
              :class="{'dev-onClick':lwzt === '1'}"
              class="dev-zt_zx_top"
            >
              <img
                class="dev-zt-img"
                src="/monitor/images/tj/left-zx.png"
              >
              <div

                style="margin:0 10px"
              >
                在线
              </div>
              <div
                v-for="(item,index) in zxNum"
                :key="index"
                class="dev-zt-num"
              >
                {{ item }}
              </div>
            </dev>
            <dev
              :class="{'dev-onClick-bg':lwzt === '1'}"
              class="dev-zt_lx_bom"
            />
          </div>

          <div
            class="dev-zt_zx"
            @click="lwzt='0'"
          >
            <dev
              :class="{'dev-onClick':lwzt === '0'}"
              class="dev-zt_zx_top"
            >
              <img
                class="dev-zt-img"
                src="/monitor/images/tj/left-lx.png"
              >
              <div style="margin:0 10px">
                离线
              </div>
              <div
                v-for="(item,index) in lxNum"
                :key="index"
                class="dev-zt-num"
              >
                {{ item }}
              </div>
            </dev>
            <dev
              :class="{'dev-onClick-bg':lwzt === '0'}"
              class="dev-zt_lx_bom"
            />
          </div>
        </div>

        <dev class="dev-center">
          <dev
            v-for="item in filterDwList"
            :key="item.id"

            class="dev-center_item"
            @click="changeDw(item.code)"
          >
            <dev
              :class="{'dev-onClick-item': activeMonitoringRoom===item.code}"
              class="dev-center_item_body"
            >
              <img
                class="dev-center_item_body_img"
                :src="`/monitor/images/tj/${activeMonitoringRoom===item.code ? 'left-jz2' : 'left-jz'}.png`"
              >

              <div style="margin-left:20px">
                {{ item.name }}
              </div>
              <div style="position: relative">
                <!-- 火灾显示火警标志-->
                <!-- <img
                  v-if="item.hjflag==='Y'"
                  src="/monitor/images/hj/火灾.svg"
                  class="hjlb-item-huozai"
                  :title="imgTitle"
                  @click="openHjym(item.code)"
                > -->
              </div>
            </dev>
            <dev class="dev-center_item_xhx" />
          </dev>
        </dev>

        <div class="dev-bom-fy">
          <el-pagination
            :current-page.sync="total"
            :page-size="pageSize"
            layout="total, prev, pager, next"
            :total="total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import introduceJs from '@/features/introuduce/index.vue';
import EventBus from '@/event-bus';

export default {
  name: 'ChartBox',
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [
          // {
          //   code: 1,
          //   name: '鸿蒙智慧消防实验室',
          //   yxsc: '926'
          // }
        ];
      }
    },
    activeMonitoringRoom: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      lwzt: '1',
      zxNum: '',
      lxNum: '',
      introduction: {
        jqrjks: {
          style: []
        }
      },
      // 每页数据量
      pageSize: 15,
      // 当前页
      pageCurrent: 1,
      // 数据总量
      total: 0,
      imgTitle: '该单位存在真实或待确认火警情况'
    };
  },
  computed: {
    filterDwList () {
      const _this = this;
      if (!_this.dwList) return [];
      var dataList = JSON.parse(JSON.stringify(_this.dwList));
      if (_this.lwzt === '1') {
        dataList = dataList.filter(item => item.lwzt === '1');
        _this.zxNum = dataList.length.toString();
      } else {
        dataList = dataList.filter(item => item.lwzt === '0');
        _this.lxNum = dataList.length.toString();
      }

      if (_this.lxNum === '' || _this.lxNum === '0') {
        _this.lxNum = _this.dwList.filter(item => item.lwzt === '0').length.toString();
      }

      // 前端分页处理
      _this.total = dataList.length;
      console.log(dataList);
      console.log(_this.total);
      if (_this.pageCurrent) {
        dataList = dataList.slice((_this.pageCurrent - 1) * _this.pageSize, _this.pageCurrent * _this.pageSize);
      }
      return dataList;
    }

  },
  methods: {
    openHjym (dwId) {
      this.$parent.changeDw(dwId);
      this.$parent.handleOHj(dwId);
      // EventBus.$emit('controlShowHj', true, false);
    },
    changeDw (dwId) {
      this.$parent.changeDw(dwId);
    },
    handleSizeChange (val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange (val) {
      this.pageCurrent = val;
      console.log(`当前页: ${val}`);
    },

    handleYjcg () {
      console.log(this.filterDwList.length);
      EventBus.$emit('DpYjcg');
    }
  },
  mounted () {
    /**
     * 语音场景介绍
     */
    const _this = this;
    EventBus.$on('introduction_goto_sys', () => {
      //  查找实验室
      const sys = _this.dwList.filter(item => item.name.indexOf('物资大厦') > -1);
      // 跳转
      if (sys.length > 0) {
        _this.changeDw(sys[0].botid);
      }
    });
  },
  created () {
    const _this = this;
    EventBus.$on('introductionZt_Left_start', function (data) {
      introduceJs.activeElement(_this.introduction, data);
    });
    EventBus.$on('introductionZt_Left_stop', function (data) {
      introduceJs.clearStyle(_this.introduction);
    });
  },

  destroyed () {

  }
};
</script>

<style scoped lang="scss">
@import '../monitor.scss';

@import '../../introuduce/introduce.css';

.monitor_center_box-body{

  height: 100%;
  margin-top: 50px;
  margin: 40px 20px;
}
.dev-left-bg{
  // width: 362px;
  height: 100%;
  // background: url('/monitor/images/tj/left-bg.png') center center no-repeat;

}

  .dev-title-jks{
  display: flex;
    align-items: center;
    position: relative;
    top: 26px;
    margin: auto;
    width: 329px;
    height: 30px;
    background: url('/monitor/images/tj/left-jks.png');

  &_test{
    margin-top: -7px;

    margin-left: 25px;
    font-family: HYYaKuHei-85J;
    font-weight: 85J;
    font-size: 16px;
    color: #FFFFFF;
  }

}
.dev-onClick{
  color: #3DFBFF !important;
}

.dev-onClick-bg{
  background: url('/monitor/images/tj/left-zx-bg.png') !important;
}
.dev-onClick-jz{
  background: url('/monitor/images/tj/left-jz2.png') !important;
}
.dev-onClick-item{
  opacity: 0.68;
  background-image: linear-gradient(270deg, #0963d600 0%, #0963d6c2 35%, #0963d6c2 70%, #0963d600 100%);
}
.dev-zt{
    width: 100%;
    height: 51px;
    z-index: 10;
    display: flex;
    &_zx{
      width: 50%;
      height: 100%;
      font-family: AlibabaPuHuiTi_2_85_Bold;
      font-weight: 700;
      font-size: 16px;

      &_top{
        display: flex;
        align-items: center;
        height: 50%;
        color: #fff;
      }
      &_bom{
        display: flex;
        margin-top: -5px;
        width: 153px;
        height: 28px;
        background: url('/monitor/images/tj/left-zx-bg.png');
      }

    }
  &_lx_bom{
        display: flex;
        margin-top: -5px;
        width: 153px;
        height: 28px;
        background: url('/monitor/images/tj/left-lx-bg.png');
      }
  }
  .dev-zt-num{
    text-align: center;
    line-height: 24px;
    width: 18px;
    height: 24px;
    background-image: linear-gradient(180deg, hsla(213.1, 100%, 44.1%, 0.1) 0%, hsla(213.3, 100%, 40.6%, 0.49) 100%);
    box-shadow: inset 0 0 2px 1px hsla(211, 100%, 63.5%, 0.38);
    border-radius: 2px;
    margin: 0 3px;
  }
  .dev-zt-img{
    width: 28px;
    height: 32px;
    margin-left: 20px;
  }

  .dev-center{
    display: inherit;
    margin-top: 10px;
    &_item{

      font-family: AlibabaPuHuiTi_2_55_Regular;
      font-weight: 400;
      font-size: 14px;
      // margin: 14px 0;
      &_body{
        display: flex;
        height: 42px;
        line-height: 53px;
        &_img{
          width: 28px;
          height: 34px;
          margin-top: 10px;
        }
      }
      &_xhx{
        display: flex;
        height: 1px;
        opacity: 0.4;
        background: #195796;
      }

    }

  }
  .dev-bom-fy{
    display: flex;
    justify-content: center;
    position: absolute;
    bottom: 60px;
    right: 18%;
    left: 10%;
  }

  .dev-bom-fy  >>> .uni-pagination__total{
  font-family: SourceHanSansSC-Regular;
  font-weight: 400;
  font-size: 14px;
  color: #00AFFF;
}

.dev-bom-fy  >>> .el-pager li.active{
  opacity: 0.36;
  border: 1px solid #00AFFF;
  border-radius: 2px;
  background: #0d1424;
  padding: 0px 4px;
  line-height: 20px;
}
.dev-bom-fy  {
  ::v-deep .el-pagination__total {
    font-family: SourceHanSansSC-Regular;
    font-weight: 400;
    font-size: 14px;
    color: #00AFFF;
  }
  ::v-deep .el-pager li {
    font-size: 14px;
    color: #fff;
    background: #001334;
  }
  ::v-deep .el-pager li.active {
    color: #00AFFF;

  }

  ::v-deep .btn-prev {
    background: #1A1311;
    width: 16px;
    height: 16px;
    opacity: 0.36;
    border: 1px solid #00AFFF;
    border-radius: 2px;
    margin-top: 5px;
  }
  ::v-deep .btn-prev .el-icon {
    margin-left: -4px;
    color:#00AFFF;

  }

  ::v-deep button:disabled {

    background: #1A1311;
  }

  ::v-deep .btn-next {
    background: #1A1311;
    width: 16px;
    height: 16px;
    opacity: 0.36;
    border: 1px solid #00AFFF;
    border-radius: 2px;
    margin-top: 5px;
  }
  ::v-deep .btn-next .el-icon {
    margin-left: 4px;
    color: #00AFFF;

  }

  ::v-deep button:disabled {

    background: #1A1311;
  }
}

.dev-yjcg{
  display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 103px;
    position: absolute;
    right: 0px;
    top: -10px;
    border: 1px solid #03A9F4;
    text-align: center;
    line-height: 32px;

    border-radius: 4px;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-weight: 700;
    font-size: 14px;
    background: #002F72;
    -webkit-box-shadow: inset 0 0 8px 0 #0066ff80;
    box-shadow: inset 0 0 8px 0 #00D1FE;
    color: #ffff;

}
.dev-yjcg:hover{
  // border: 1px solid #48ff00;
  border: 1px solid #eaece9;
}

.dev-cg-img{
  width: 24px;
  height: 24px;
  margin: 0 5px;
}

.hjlb-item-huozai{
  width: 24px;
  height: 20px;
  position: absolute;
  margin: auto;
  top: 0;
  left: 150px;
  right: 0;
  bottom: 0;
}
</style>
