<template>
  <div class="monitor_screen-chart">
    <div
      :class="introduction.ydbjl.style"
      class="monitor_schart monitor_spra-wrap"
    >
      <div style="display: flex;flex-wrap: nowrap;">
        <div style="width:50%">
          <sub-title
            inner-text="今日电气报警"
            style="margin-top: 25px"
          />
        </div>
        <div
          class="dev-yjcg"
          style="z-index: 999;"
          @click="queryPath"
        >
          <img
            class="dev-cg-img"
            src="/monitor/images/tj/dqhzxt.svg"
          >
          <span>电气火灾智能防控系统</span>
        </div>
      </div>
      <fxsstj />
      <DqhzIframe
        ref="Iframe"
        v-loading="loading"
        :url="path"
        :iframe-visible="iframeVisible"
      />
    </div>
    <div
      :class="introduction.jrsbzt.style"
      class="monitor_schart monitor_spra-wrap"
      style="height: 250px;"
    >
      <sub-title inner-text="今日电气设备状态" />
      <div
        style="display: flex;align-items: center;height: 220px;"
      >
        <Jzsbzt />
        <!-- <Jrsbbjqk3D /> -->
      </div>
    </div>
    <div
      :class="introduction.bjtj.style"
      class="monitor_schart monitor_study-wrap"
    >
      <sub-title inner-text="最近6个月关键报警次数" />
      <div>
        <gjbjcsLeft />
      </div>
    </div>
  </div>
</template>

<script>
import fxsstj from './fxsstj.vue';
import Jzsbzt from './jzsbzt.vue';
// import Jrsbbjqk3D from './jrsbbjqk3D.vue';
import DqhzIframe from '../detail/dqhzFrameBox.vue';

import SubTitle from '../components/SubTitle2.vue';

import { monitorService } from '../monitor.service';
import introduceJs from '@/features/introuduce/index.vue';
import EventBus from '@/event-bus';

import gjbjcsLeft from '../components/gjbjcsLeft.vue';
export default {
  name: 'NewRightBox',
  components: {
    // Jrsbbjqk3D,
    Jzsbzt,
    fxsstj,
    DqhzIframe,
    SubTitle,
    gjbjcsLeft
  },
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    activeMonitoringRoom: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      graph: null,
      loading: false,
      path: '',
      iframeVisible: false,
      sbzt: null, // 今日设备状态
      introduction: {
        jrhj: {
          style: []
        },
        ydbjl: {
          style: []
        },
        ydjkbg: {
          style: []
        },
        bjtj: {
          style: []
        },
        xfsbsl: {
          style: []
        },
        jrsbzt: {
          style: []
        }
      }
    };
  },
  computed: {
  },
  methods: {
    // 调用方法
    iframeLoad () {
      this.loading = true;
      const iframe = this.$refs.Iframe;
      // 兼容处理
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent('onload', () => {
          this.loading = false;
        });
      } else {
        // 非IE
        iframe.onload = () => {
          this.loading = false;
        };
      }
    },
    handleMessage (event) {
      // console.log('event.data111', event.data);

      if (event.data instanceof Boolean && !event.data) {
        this.loading = false;
        this.iframeVisible = event.data;
      } else if (event.data instanceof Boolean && event.data) {
        this.iframeVisible = true;
      } else {
        this.loading = false;
        this.iframeVisible = false;
      }
    },
    queryPath () {
      this.iframeLoad();
      monitorService.queryPath()
        .then(rsp => {
          if (rsp.data && rsp.data.code === 200) {
            this.path = rsp.data.data;
            console.log('查询路径', this.path);
            this.iframeVisible = true;
          } else {
            this.iframeVisible = false;
          }
        });
    },
    queryDqsbzt () {
      monitorService.queryDqsbzt().then(resp => {
        console.log('获取设备状态信息', resp.data);
        this.sbzt = resp.data;
      });
    }
  },
  watch: {
    dwList () {
      // this.updateGraphXfsbChart();
    },
    activeMonitoringRoom () {
      // this.resizeGrapha();
    }
  },
  mounted () {
    console.log('this.$refs', this.$refs);
    // 图表自适应宽度
    // 注册监听框架传来的数据
    this.$nextTick(() => {
        // 在外部 Vue 的 window 上添加 postMessage 的监听，并且绑定处理函数 handleMessage
        // ifream 传递过来的数据 关闭ifream
        window.addEventListener('message', this.handleMessage);
      });
  },
  created () {
    const _this = this;
    EventBus.$on('introductionZt_Right_start', function (data) {
      introduceJs.activeElement(_this.introduction, data);
    });
    EventBus.$on('introductionZt_Right_stop', function (data) {
      introduceJs.clearStyle(_this.introduction);
    });
  },

  destroyed () {
    clearInterval(this.clock);
    // 注意移除监听！
    window.removeEventListener('message', this.handleMessage);
  }
};
</script>

<style lang="scss" scoped>
@import '../monitor.scss';
@import '../../introuduce/introduce.css';

.dev-cg-img{
  width: 24px;
  height: 24px;
  margin: 0 5px;
}

.dev-yjcg{
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    position: absolute;
    right: 5px;
    top: 16px;
    border: 1px solid #03A9F4;
    text-align: center;
    line-height: 32px;

    border-radius: 4px;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-weight: 700;
    font-size: 14px;
    background: #002F72;
    -webkit-box-shadow: inset 0 0 8px 0 #0066ff80;
    box-shadow: inset 0 0 8px 0 #00D1FE;
    color: #ffff;
    z-index: 999;

}
.dev-yjcg:hover{
  // border: 1px solid #48ff00;
  border: 1px solid #eaece9;
}
</style>
