<template>
  <div style="height: 230px;">
    <div
      id="graphXfsc"
      ref="graphXfsc"
      :style="'width:100%;height:100%;position:relative;top:-20px'"
    />
  </div>
</template>

<script>
import { monitorService } from '../monitor.service';

export default {
  name: 'Xfsc',
  props: {},
  data () {
    return {
      graphXfsc: null,
      xfsSbList: [],
      scData: [
        {
          name: '消防水达标', // x轴名称
          label: '消防水达标', // 标签描述
          value: 100 // 占比
        },
        {
          name: '消防水不达标',
          label: '中央大街',
          value: 0

        }
      ]
    };
  },
  methods: {
    getData () {
      monitorService.listXfsSb()
        .then(rsp => {
          this.xfsSbList = rsp.data;
          console.log('消防水池设备列表', this.xfsSbList);
          this.initData();
        })
        .catch(err => {
          console.error('获取消防水池信息错误！', err);
        });
    },
    init () {
      this.graphXfsc = this.$echarts.init(this.$refs.graphXfsc);
      this.getData();
      //
      // let currentIndex = -1;
      // const dataLength = this.scData.length;
      // setInterval(() => {
      //   // 取消之前高亮的柱子
      //   this.graphXfsc.dispatchAction({
      //     type: 'downplay',
      //     seriesIndex: 0,
      //     dataIndex: currentIndex
      //   });
      //   // 计算下一个要高亮的柱子的索引
      //   currentIndex = (currentIndex + 1) % dataLength;
      //   // 高亮下一个柱子
      //   this.graphXfsc.dispatchAction({
      //     type: 'highlight',
      //     seriesIndex: 0,
      //     dataIndex: currentIndex
      //   });
      // }, 2000); // 每2000
    },
    getSeries (scList, lx) {
      const color = new this.$echarts.graphic.LinearGradient(
        0, 0, 0, 1,
        [{
          offset: 0,
          color: '#0884ee'
        },
          {
            offset: 1,
            color: '#63eff8'
          }
        ]
      );

      return {
        type: 'bar',
        data: scList,
        barWidth: 40, // 柱形宽度
        showBackground: false, // 柱形背景色
        itemStyle: { // 柱形渐变色
          normal: {
            barBorderRadius: 4, // 柱形圆角
            color,
            shadowBlur: 0,
            shadowColor: 'rgb(245,236,236)'
          }
        },
        label: { // 显示当前柱形数值
          show: true,
          position: 'top',
          formatter: (params) => Math.round(params.value * 10) / 10 + '%',
          textStyle: {
            color: '#15d0dd', // 更改坐标轴文字颜色
            fontSize: 13 // 更改坐标轴文字大小
          }
        },
        emphasis: { // 鼠标悬浮时高亮样式
          itemStyle: {
            shadowColor: 'rgba(255, 255, 255, 0.9)',
            shadowBlur: 20
          }
        }
      };
    },
    initData () {
      // 数据处理
      const total = this.xfsSbList.length;
      const standard = this.xfsSbList.filter(p => p.bjztZl === '01_ZC' || p.bjztZl === '' || p.bjztZl == null).length;
      const nonStandard = this.xfsSbList.filter(p => p.bjztZl === '01_DW' || p.bjztZl === '01_GW').length;
      const standardPercentage = (standard / total) * 100;
      const nonStandardPercentage = (nonStandard / total) * 100;

      // 按单位名称分类统计
      const dwmcStats = this.xfsSbList.reduce((acc, pool) => {
        if (!acc[pool.dwmc]) {
          acc[pool.dwmc] = { standard: 0, nonStandard: 0 };
        }
        if (pool.bjztZl === '01_ZC' || pool.bjztZl === '' || pool.bjztZl == null) {
          acc[pool.dwmc].standard++;
        } else if (pool.bjztZl === '01_DW' || pool.bjztZl === '01_GW') {
          acc[pool.dwmc].nonStandard++;
        }
        return acc;
      }, {});

      const seriesList = [
        this.getSeries([standardPercentage, nonStandardPercentage])
      ];

      const option = {
        legend: {
          selectedMode: true,
          show: false
        },
        grid: {
          left: 100,
          right: 100,
          top: 50,
          bottom: 50
        },
        yAxis: {
          type: 'value',
          nameTextStyle: {
            padding: [0, 30, 5, 0], // y轴name位置
            color: '#b1bcff', // 更改单位文字颜色
            fontSize: 15 // 更改单位文字大小
          },
          splitLine: { // 网格线
            lineStyle: {
              // type: 'dashed', //设置网格线类型 dotted：虚线   solid:实线
              color: '#374186', // 网格线颜色
              width: 0.5
            }
          },
          axisLabel: {
            show: true,
            formatter: function (value) {
              return value + '%';
            },
            textStyle: {
              color: '#b1bcff', // 更改坐标轴文字颜色
              fontSize: 15 // 更改坐标轴文字大小
            }
          },
          axisLine: {
            // y轴线条颜色
            show: false
          },
          minInterval: 25,
          animation: true,
          min: 0, // 设置最小值为0
          max: 100 // 设置最大值为1
        },
        xAxis: {
          type: 'category',
          data: ['消防水达标', '消防水未达标'],
          // boundaryGap: false,//去除图表四周留白
          axisTick: { // x轴刻度
            show: false
          },
          axisLine: { // x轴线条颜色
            show: true,
            lineStyle: {
              color: '#374186',
              width: 0.5
            }
          },
          axisLabel: {
            show: true,
            // rotate: 40, // 文字倾斜度
            textStyle: {
              color: '#b1bcff', // 更改坐标轴文字颜色
              fontSize: 14 // 更改坐标轴文字大小
            }
          }
        },
        series: seriesList,
        tooltip: {
          trigger: 'item',
          show: true,
          formatter: function (params) {
            console.log(params);
            const type = params.name === '消防水达标' ? 'standard' : 'nonStandard';
            return Object.entries(dwmcStats)
              .filter(([_, counts]) => counts[type] > 0) // 过滤掉数量为0的单位
              .map(([dwmc, counts]) => {
                return `${dwmc}: ${counts[type]}个${type === 'standard' ? '达标' : '未达标'}`;
              }).join('<br>');
          }
        },
        graphic: { // 自定义图形元素
          type: 'text',
          right: 20, // 距离右边的位置
          top: 60, // 距离顶部的位置
          style: {
            text: '', // 初始文本为空
            fill: '#ffffff', // 文本颜色
            font: '14px Arial' // 字体样式
          },
          z: 100 // 确保文本显示在最上层
        }
      };
      console.log('this.graphXfsc', option);
      this.graphXfsc.setOption(option);
    },
    initData2 () {
      // 数据处理
      const total = this.xfsSbList.length;
      const standard = this.xfsSbList.filter(p => p.bjztZl === '01_ZC' || p.bjztZl === '' || p.bjztZl == null).length;
      const nonStandard = this.xfsSbList.filter(p => p.bjztZl === '01_DW' || p.bjztZl === '01_GW').length;
      const standardPercentage = (standard / total) * 100;
      const nonStandardPercentage = (nonStandard / total) * 100;

      // 按单位名称分类统计
      const dwmcStats = this.xfsSbList.reduce((acc, pool) => {
        if (!acc[pool.dwmc]) {
          acc[pool.dwmc] = { standard: 0, nonStandard: 0 };
        }
        if (pool.bjztZl === '01_ZC' || pool.bjztZl === '' || pool.bjztZl == null) {
          acc[pool.dwmc].standard++;
        } else if (pool.bjztZl === '01_DW' || pool.bjztZl === '01_GW') {
          acc[pool.dwmc].nonStandard++;
        }
        return acc;
      }, {});

      // ECharts 配置
      const option = {
        xAxis: {
          type: 'category',
          data: ['消防水达标', '消防水未达标']
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            formatter: '{value} %'
          }
        },
        series: [
          {
            data: [standardPercentage, nonStandardPercentage],
            type: 'bar'
          }
        ],
        tooltip: {
          trigger: 'item',
          show: true,
          formatter: function (params) {
            console.log(params);
            const type = params.name === '消防水达标' ? 'standard' : 'nonStandard';
            return Object.entries(dwmcStats)
              .filter(([_, counts]) => counts[type] > 0) // 过滤掉数量为0的单位
              .map(([dwmc, counts]) => {
              return `${dwmc}: ${counts[type]}个${type === 'standard' ? '达标' : '未达标'}`;
            }).join('<br>');
          }
        }
      };

      this.graphXfsc.setOption(option);
    }
  },
  mounted () {
    this.init();
  }
};
</script>

<style lang="
      less"
       scoped
>
</style>
