<!--
 * @Author: DQL
 * @Date: 2022-11-30 10:13:40
 * @LastEditors: PXH
 * @LastEditTime: 2023-12-25 20:03:06
 * @Description: file content
-->
<template>
  <div
    v-show="dataList.length > 0"
    class="c-sinfo"
  >
    <div class="c-sinfo__header">
      <span class="c-sinfo__header_title">今日报警记录</span>
    </div>
    <div
      v-show="filterList.length > 6"
      class="div-gd-kg"
      @click="handlGbgd()"
    >
      {{ gdIshowWz }}
    </div>
    <div
      v-show="filterList.length !== dataList.length"
      class="div-gd-kg_ckqb"
      @click="handlCkqb()"
    >
      查看全部
    </div>
    <div
      id="div-jrfxsssj"
      class="div-body"
    >
      <div class="div-body-content">
        <div
          v-for="(item,index) in filterList"
          :key="index"

          class="div-body_item"
        >
          <div class="div-item_left">
            <div
              class="div-item_img"
              :class="{
                'div-item_img_zs' : item.xxlx === '1' && item.xxqr === '2' ,
                'div-item_img_gz' : item.xxlx === '2' ,
                'div-item_img_wb' : item.xxlx === '1' && (item.xxqr === '3' || item.xxqr === '1' ) ,
                'div-item_img_dqr': item.xxlx === '1' && !item.xxqr ,
              }"
            >
            <!-- <img src="/monitor/images/top/t_zshj.png"> -->
            </div>
            <div
              :class="{

                'div-item_xxlx_zs' : item.xxlx === '1' && item.xxqr === '2' ,
                'div-item_xxlx_gz' : item.xxlx === '2' ,
                'div-item_xxlx_wb' : item.xxlx === '1' && (item.xxqr === '3' || item.xxqr === '1' ) ,
                'div-item_xxlx_dqr': item.xxlx === '1' && !item.xxqr ,
              }"
            >
              {{ item.xxlx==='1'?'火警':'故障' }}
            </div>
          </div>
          <div style="margin-left: 20px;  width: 100px">
            {{ item.bjmc }}
          </div>

          <div class="div-azwz">
            <!-- <image
            class="dev-jz-img"
            src="@/static/iffIcons/tj/建筑.png"
          /> -->
            <div>{{ item.azwz }}</div>
          </div>

          <div style="margin: 20px;">
            {{ item.scjbsj }}
          </div>
          <div style="margin: 20px;">
            {{ item.xxqrr }}
          </div>
          <div
            class="div-right"
            style="margin-right: 20px"
          >
            <div
              v-if="item.xxlx === '1'"
              :class="{
                'div-xxqr-dqr' : !item.xxqr ,
                'div-xxqr-1' : item.xxqr === '1' || item.xxqr === '3' ,
                'div-xxqr-2' : item.xxqr === '2' ,

              }"
            >
              {{ !item.xxqr?'待确认火警': item.xxqr==='2'?'真实火警': item.xxqr ==='3'?'维保测试':item.cljg }}
            </div>

            <div
              v-if="item.xxlx === '2'"
              :class="{
                'div-xxqr-dqr' : !item.xxqr ,
                'div-xxqr-1' : item.xxqr === '1' || item.xxqr === '3' ,
                'div-xxqr-2' : item.xxqr === '2' ,

              }"
            >
              {{ !item.xxqr?'待确认故障': item.xxqr==='2'?'真实故障': item.xxqr ==='3'?'维保测试':item.cljg }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { monitorService } from '../monitor.service';
import EventBus from '@/event-bus';
export default {
  name: 'Jrfxsssj',
  props: {
    code: {
      type: String,
      default: function () {
        return '';
      }
    }
  },
  data () {
    return {
      loading: false,
      gdIshow: true,
      gdIshowWz: '关闭滚动',
      bgStr: 'div-xxlx-bg_',
      dataList: [
      ],
      clock: null,
      xxlx: '',
      xxqr: ''
    };
  },
    computed: {
    filterList () {
      var dataList = this.dataList;
      if (this.xxlx) {
       dataList = dataList.filter(item => {
            return item.xxlx === this.xxlx;
        });
      }
      if (this.xxqr === '2' || this.xxqr === null) {
       dataList = dataList.filter(item => {
            return item.xxqr === this.xxqr;
        });
      }
      if (this.xxqr === '1') {
       dataList = dataList.filter(item => {
            return item.xxqr === '1' || item.xxqr === '3';
        });
      }
      console.log('filterList', dataList);
      return dataList;
    }
  },
  methods: {
    handlCkqb () {
        this.xxlx = '';
        this.xxqr = '';
    },

    /**
     * 获取当前电气火灾设备情况
     */
    queryJrfxSJ () {
      const _this = this;
      monitorService.queryTodyaSlxx(_this.code)
        .then(res => {
          _this.dataList = res.data;
          console.log('查询今日的受理信息', res.data);
        })
        .catch(error => {
          console.log('获取今日的受理信息信息失败！', error);
        })
        .finally(() => {

        });
    },
    handlGbgd () {
      this.gdIshow = !this.gdIshow;
      this.gdIshowWz = this.gdIshow ? '关闭滚动' : '开启滚动';
    }

  },
  watch: {
    code (newVal, oldVal) {
      console.log('code', newVal, oldVal);
      const _this = this;
        this.queryJrfxSJ();
        this.clock = setInterval(function () {
          if (newVal) {
            _this.queryJrfxSJ();
          } else {
            clearInterval(_this.clock);
          }
        }, 10 * 1000);
    }

  },
  beforeDestroy () {
    // this.autoScroll(true);
  },

  mounted () {
    const _this = this;
    // _this.autoScroll();
    _this.queryJrfxSJ();
    this.clock = setInterval(function () {
      if (_this.code) {
            _this.queryJrfxSJ();
        } else {
          clearInterval(_this.clock);
      }
    }, 10 * 1000);

    // // 拿到表格挂载后的真实DOM
    const divData = document.getElementById('div-jrfxsssj');
    setInterval(() => {
      if (!_this.gdIshow) return;
      // 元素自增距离顶部1像素
      divData.scrollTop += 1;
      // 判断元素是否滚动到底部(可视高度+距离顶部=整个高度)
      if (divData.clientHeight + divData.scrollTop === divData.scrollHeight) {
        // 重置table距离顶部距离
        divData.scrollTop = 0;
      }
    }, 50);

    EventBus.$on('topBjzt', function (data) {
      console.log('topBjzt', data);
      _this.xxlx = data.xxlx;
      _this.xxqr = data.xxqr;
    });

    // const
  },
    destroyed () {
    clearInterval(this.clock);
     // 注意移除监听！
    //  window.removeEventListener('message', this.handleMessage);
  }
};
</script>

<style lang="less" scoped>

.c-sinfo{
  box-shadow: inset 0 0 18px 2px #0093ff66;
  // background: url('/images/screen/bottom-box-bg.svg') no-repeat center top;
  // background-size: cover;
  background-image: linear-gradient(180deg, #001235f0 0%, #011733f0 100%);

  &__header{
    height: 30px;
    background: url('/monitor/images/tj/bottom.png');

    &_title{
      margin-left: 25px;
      font-family: HYYaKuHei-85J;
      font-size: 16px;
      font-weight: 85J;
      line-height: 24px;
      color: #FFF;

    }
  }

  &__body{
    display: flex;
    width: 100%;
    padding: 20px 24px 0 20px;
  }
}

.div-gd-kg{
  width: 56px;
  padding: 4px;
  border-radius: 2px;
  background-color: #3263d1;
  color: #fff;
  position: absolute;
  right: 20px;
  top: 20px;
}
.div-gd-kg_ckqb{
  width: 56px;
  padding: 4px;
  border-radius: 2px;
  background-color: #3263d1;
  color: #fff;
  position: absolute;
  left: 210px;
  top: 20px;
}
.div-body{
  // font: 20px;
   width: 95%;
  height: 250px;
  overflow-y: auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: auto;
  margin-top: 15px;

  &_item{
    display: flex;
    width: 100%;
    height: 36px;
    background-color: rgb(3, 37, 77);
    align-items: center;
    position: relative;
    margin: 2px;
    border-radius: 3px;
    // animation: scroll 5s linear infinite;
  }

}
/* 鼠标悬停停止滚动 */
// .div-body_item:hover{
//   animation-play-state: paused;
// }
@keyframes scroll {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-100%);
  }
}

.div-body::-webkit-scrollbar:vertical {
  width: 6px;
}

.div-body::-webkit-scrollbar-thumb {
  background: #09369A;
}
.div-body::-webkit-scrollbar-track {

  background: #08193F;
}
.div-body-content {
  // flex-grow: 1;
  // display: flex;
  // flex-direction: column;
  // justify-content: flex-end;
  // background-color: #011233;

}
.div-item_left{
  display: flex;
  align-items: center;
  width: 70px;
  height: 27px;
  padding: auto;
  background-image: url('/monitor/images/top/t_bj.png');
  background-size: 100% 100%;
  margin-left: 10px;
  }

.div-item_img{
  width: 20px;
  height: 20px;
  //  background: url('/monitor/images/top/t_zshj.png') no-repeat center right;
  // background-size: 100% 100%;
  margin: 0 5px;

  &_zs{
    background: url('/monitor/images/top/t_zshj.png') no-repeat center right;
    background-size: 100% 100%;
  }
  &_gz{
    background: url('/monitor/images/top/t_gz.svg') no-repeat center right;
    background-size: 100% 100%;
  }
  &_wb{
    background: url('/monitor/images/top/t_wb2.png') no-repeat center right;
    background-size: 100% 100%;
  }
  &_dqr{
    background: url('/monitor/images/top/t_dqr.png') no-repeat center right;
    background-size: 100% 100%;
  }
}
.div-item_xxlx{
  padding: 2px;
  font: 20px;

  &_zs{
   color: #E1574D;
  }
  &_gz{
   color: rgb(221 140 6);
  }
  &_wb{
   color: #BDCAE1
  }
  &_dqr{
   color: #F1DD55
  }
}
.div-item_num{

  display: flex;
  width: 60%;
  margin-left: 200px;
  &_test{
    width: 25%;
    margin: auto;
    font-size: 22px;
    color: #fff;
  }
}
.div-xxlx-item{
  padding: 2px;
  font: 20px;
  &_hj{
  color: #e1574d;
  }
  &_gz{
  color: #a5b3cb;
  }
}
.div-right{
  display: flex;
  align-items: center;
  position: absolute;
  right: 0px;
}
.dev-jz-img{
  width: 14px;
  height: 12.9px;
  margin: 5px;
}
.div-azwz{
  display: flex;
  align-items: center;
  width: 300px;
  margin: 20px;
}
.div-xxqr-1{
  color: #47d215;
}
.div-xxqr-2{

  color: #e1564d;
}
.div-xxqr-dqr{
  color: #e7e409;
}

</style>
