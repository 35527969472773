<template>
  <div id="Iframe">
    <el-dialog
      append-to-body
      style="margin-top: -8vh;margin-bottom: -8vh;"
      custom-class="scrollbar"
      :visible.sync="visible"
      width="100%"
      @close="close"
    >
      <iframe
        id="iframe"
        :key="key"
        ref="iframeBox"
        :src="url"
        frameborder="0"
        width="100%"
        height="100%"
        sandbox="allow-forms allow-scripts allow-same-origin allow-popups"
        scrolling="auto"
      />
    </el-dialog>
  </div>
</template>

  <script>

  export default {
    name: 'DqhzIframe',
    props: {
        url: {
            type: String,
            default: ''
        },
        iframeVisible: {
            type: Boolean,
            default: false
        }
    },
    data () {
      return {
        visible: false,
        key: new Date().getTime()
      };
    },
    computed: {
      // 计算属性 , 设置iframe高度为窗口高度少100px
      calHeight () {
        return window.innerHeight + 'px';
      }
    },
    methods: {
        // 点击关闭按钮或者ifream之外的区域会执行该方法。通知父组件关闭弹窗
      close () {
        window.parent.postMessage(false, '*'); // 关闭iframe所在的弹窗
      }
    },
    watch: {
      iframeVisible (newVal, oldVal) {
            this.visible = newVal;
        },
      $route: {
        handler (val) {
          this.key = new Date().getTime();
        },
        immediate: true
      }
    }
  };
 </script>
 <style lang="scss">
  #Iframe {
    width: 100%;
      height: 100%;
    // padding: 0 10vw;
    z-index: -1 !important;

    .el-dialog__header {
    //   display: none;
      background-color: transparent !important;
      padding: 0;
      height: 0px;
    }
    .el-dialog__body {
      background-color: #f9fafc;
      border-radius: 10px;
      padding: 0px 0px 0px;
      height: calc(100vh - 80px);
      overflow: hidden;
    //   overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
      &::-webkit-scrollbar-button {
        display: none;
      }

      // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
      &::-webkit-scrollbar-thumb {
        background: rgba(144, 147, 153, 0.3);
        cursor: pointer;
        border-radius: 4px;
      }

      // 边角，即两个滚动条的交汇处
      &::-webkit-scrollbar-corner {
        display: none;
      }

      // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
      &::-webkit-resizer {
        display: none;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #909399;
      width: 50px;
      height: 20px;
      line-height: 20px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
    }
    // 发起新的弹窗
    .dialog-footer {
      height: 30px;
      line-height: 30px;
      background: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
      border-radius: 5px;
      flex-wrap: wrap;
      margin-top: 20px;
    }
    .dialog-header {
      height: 30px;
      line-height: 30px;
      background: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
      border-radius: 5px;
      flex-wrap: wrap;
      margin-bottom: 20px;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 600;
    }
    .center {
      display: flex;
      width: 100%;
      background: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
      border-radius: 5px;
      padding: 20px;
    }
  }
  .scrollbar {
    width: 100%;
    // height: 100%;
    //   max-height: max-content;
    // padding: 0 10vw;
    z-index: -1 !important;

    .el-dialog__header {
    //   display: none;
      background-color: transparent !important;
      padding: 0;
      height: 0px;
    }
    .el-dialog__body {
      background-color: #f9fafc;
      border-radius: 10px;
      padding: 0 0 0;
      height: calc(100vh - 0px);
    //   max-height: calc(100vh - 250px);
        max-height: 100%;
      overflow: hidden;
    //   overflow-y: scroll;

      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }

      // 滚动条的轨道的两端按钮，允许通过点击微调小方块的位置。
      &::-webkit-scrollbar-button {
        display: none;
      }

      // 滚动条里面的小方块，能向上向下移动（或往左往右移动，取决于是垂直滚动条还是水平滚动条）
      &::-webkit-scrollbar-thumb {
        background: rgba(144, 147, 153, 0.3);
        cursor: pointer;
        border-radius: 4px;
      }

      // 边角，即两个滚动条的交汇处
      &::-webkit-scrollbar-corner {
        display: none;
      }

      // 两个滚动条的交汇处上用于通过拖动调整元素大小的小控件
      &::-webkit-resizer {
        display: none;
      }
    }

    .el-dialog__headerbtn .el-dialog__close {
      color: #909399;
      width: 50px;
      height: 30px;
      line-height: 30px;
      border-radius: 5px;
      background-color: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
    }
    // 发起新的弹窗
    .dialog-footer {
      height: 50px;
      line-height: 50px;
      background: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
      border-radius: 5px;
      flex-wrap: wrap;
      margin-top: 20px;
    }
    .dialog-header {
      height: 60px;
      line-height: 60px;
      background: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
      border-radius: 5px;
      flex-wrap: wrap;
      margin-bottom: 20px;
      padding: 0 10px;
      font-size: 16px;
      font-weight: 600;
    }
    .center {
      display: flex;
      width: 100%;
      background: #fff;
      box-shadow: 2px 2px 6px rgba(138, 169, 192, 0.2);
      border-radius: 5px;
      padding: 20px;
    }
  }
  </style>
