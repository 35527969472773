<template>
  <div>
    <div
      ref="chartLx1"
      style="height: 140px;"
    />
    <div
      ref="chartLx2"
      style="height: 140px;"
    />
    <div
      ref="chartLx3"
      style="height: 140px;"
    />
  </div>
</template>

<script>

import * as echarts from 'echarts';
import { monitorService } from '../monitor.service';
import tools from '../tools.js';

export default {
  name: 'GjbjcsLeft',
  props: {
    loading: { type: Boolean, default: false },
    xAxis: {
      type: Array,
      default () {
        return [];
      }
    },
    xAxisRotate: { type: String, default: '0' },
    data: {
      type: Array,
      default () {
        return [];
      }
    }
  },
  data () {
    return {
      tootipTimer1: null,
      tootipTimer2: null,
      tootipTimer3: null,
      chartLx1: null,
      chartLx2: null,
      chartLx3: null,
      charOption: {
        color: [],
        tooltip: {
          trigger: 'axis',
          confine: true,
          backgroundColor: 'rgba(0,0,0,0.5)', // 设置背景色为透明
          textStyle: {
              fontSize: 12,
              color: '#fff' // 设置字体颜色为红色
          },
          // position: function (p) {
          //   // 其中p为当前鼠标的位置
          //   return [p[0] - 1, p[1] + 1];
          // },
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985'
            }
          }
        },
        legend: {
          right: '5%',
          bottom: '78%',
          textStyle: {
            color: '#fff'
          },
          show: true,
          data: []
        },
        // toolbox: {
        //   feature: {
        //     saveAsImage: {}
        //   }
        // },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: [
          {
            type: 'category',
            splitLine: {
              show: false
            },
            boundaryGap: false,
            data: []
          }
        ],
        yAxis: [
          {
            type: 'value',
            splitLine: {
              show: false
            }
          }
        ],
        series: []
      },
      resultList: [],
      resultListLx1: [],
      resultListLx2: [],
      resultListLx3: [],
      xAxisList: []
    };
  },
  methods: {
    initChart () {
      this.chartLx1 = echarts.init(this.$refs.chartLx1);
      this.chartLx2 = echarts.init(this.$refs.chartLx2);
      this.chartLx3 = echarts.init(this.$refs.chartLx3);
      this.updateChart();
      this.updateChart2();
      this.updateChart3();
    },
    queryGjbjcsTj () {
      const _this = this;

      monitorService.queryGjbjcsTj().then(rsp => {
        _this.resultList = rsp.data;
        console.log('queryGjbjcsTj', this.resultList);

        this.initChart();
      });
    },
    updateChart () {
      const option = this.charOption;
      option.color = ['#88f1f8', '#e5f60d', '#0e7bb2'];
      option.legend.data = ['过流', '过温', '漏电'];
      option.xAxis[0].data = this.xAxisList;
      option.series = [
        {
          name: '过流',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',

          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#88f1f8'
              },
              {
                offset: 1,
                color: 'rgb(1, 191, 236)'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: this.getEnumTextData('过流')
        },
        {
          name: '过温',
          type: 'line',
          stack: 'Total',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#e5f60d'
              },
              {
                offset: 1,
                color: '#e7ef89'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: this.getEnumTextData('过温')
        },
        {
          name: '漏电',
          type: 'line',
          stack: 'Total',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            // opacity: 0.8,
            color: '#0e7bb2'
          },
          emphasis: {
            focus: 'series'
          },
          data: this.getEnumTextData('漏电')
        }
      ];
      this.chartLx1.setOption(option);
      // tools.autoHover(this.chartLx1, option, this.xAxisList.length, 3000);
    },
    updateChart2 () {
      // const data = this.data;
      const option = this.charOption;
      option.color = ['#fe6606', '#e5f60d', '#3263fd'];
      option.legend.data = ['一级', '二级', '三级'];
      option.xAxis[0].data = this.xAxisList;
      option.series = [
        {
          name: '一级',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',

          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,

            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#fe6606'
              },
              {
                offset: 1,
                color: '#676c75'
              }
            ])

          },
          emphasis: {
            focus: 'series'
          },
          data: this.getRiskLevelNameData('一级')
        },
        {
          name: '二级',
          type: 'line',
          stack: 'Total',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,

            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#e5f60d'
              },
              {
                offset: 1,
                color: '#676c75'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: this.getRiskLevelNameData('二级')
        },
        {
          name: '三级',
          type: 'line',
          stack: 'Total',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,

            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#3263fd'
              },
              {
                offset: 1,
                color: '#676c75'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: this.getRiskLevelNameData('三级')
        }
      ];

      this.chartLx2.setOption(option);
      tools.autoHover(this.chartLx2, option, this.xAxisList.length, 3000);
    },
    updateChart3 () {
      // const data = this.data;
      const option = this.charOption;
      option.color = ['#38f1f8', '#fe6606'];
      option.legend.data = ['电弧光火焰', '跳闸'];
      option.xAxis[0].data = this.xAxisList;
      option.series = [
        {
          name: '电弧光火焰',
          type: 'line',
          stack: 'Total',
          smooth: true,
          symbol: 'none',

          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#38f1f8'
              },
              {
                offset: 1,
                color: '#676c75'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: this.getRiskCateName('电弧光')
        },
        {
          name: '跳闸',
          type: 'line',
          stack: 'Total',
          symbol: 'none',
          smooth: true,
          lineStyle: {
            width: 1
          },
          showSymbol: false,
          areaStyle: {
            opacity: 0.8,
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
              {
                offset: 0,
                color: '#fe6606'
              },
              {
                offset: 1,
                color: '#676c75'
              }
            ])
          },
          emphasis: {
            focus: 'series'
          },
          data: this.getRiskCateName('跳闸')
        }

      ];
      this.chartLx3.setOption(option);
    },

    resizeChart () {
      // this.chart.resize();
      this.chartLx1.resize();
      this.chartLx2.resize();
      this.chartLx3.resize();
    },
    /**
     * 生成过去6个月的日期
     */
     initXAxis () {
      const date = new Date();
      const result = [];
      for (let i = 0; i < 6; i++) {
        const newDate = new Date(date.getFullYear(), date.getMonth() - i, 1);
        const year = newDate.getFullYear();
        const month = newDate.getMonth() + 1;
        const formattedDate = `${year}年${month < 10 ? '0' + month : month}月`;
        result.unshift(formattedDate);
      }
      this.xAxisList = result;
      // console.log('当前年月', this.xAxisList);
    },
    /**
     * 生成过去一年的日期
     */
    initXAxissss () {
      this.xAxisList = [];
      const date = new Date();
      const year = date.getFullYear();
      const month = date.getMonth() + 1;

      // 生成过去12个月的日期列表
      for (let i = 7; i >= 0; i--) {
        const monthTmp = month - i;
        let yearTmp, monthStrTmp;

        if (monthTmp <= 0) {
          yearTmp = year - 1;
          monthStrTmp = 6 + monthTmp;
        } else {
          yearTmp = year;
          monthStrTmp = monthTmp;
        }

        // 月份补0
        const formattedMonth = monthStrTmp < 10 ? '0' + monthStrTmp : monthStrTmp;
        this.xAxisList.push(yearTmp + '年' + formattedMonth + '月');
      }

      // console.log('当前年月', this.xAxisList);
    },
    getRiskLevelNameData (riskLevelName) {
      // 根据xAxisList列表，来匹配resultList的ny字段，来统计数据
      const dataList = [];
      this.xAxisList.forEach(xAxis => {
        let count = 0;
        this.resultList.forEach(result => {
          if (result.ny === xAxis) {
            if (riskLevelName === result.riskLevelName) {
              count = count + 1;
            }
          }
        });
        dataList.push(count);
      });
      console.log('dataList', dataList);
      return dataList;
    },
    getEnumTextData (enumText) {
      // 根据xAxisList列表，来匹配resultList的ny字段，来统计数据
      const dataList = [];
      this.xAxisList.forEach(xAxis => {
        let count = 0;
        this.resultList.forEach(result => {
          if (result.ny === xAxis) {
            if (enumText === result.enumText) {
              count = count + 1;
            }
          }
        });
        dataList.push(count);
      });
      console.log('dataList', dataList);
      return dataList;
    },
    getRiskCateName (riskCateName) {
      // 根据xAxisList列表，来匹配resultList的ny字段，来统计数据
      const dataList = [];
      this.xAxisList.forEach(xAxis => {
        let count = 0;
        this.resultList.forEach(result => {
          if (result.ny === xAxis) {
            // result.riskCateName 包含 riskCateName的字段
            if ((result.riskCateName + '').indexOf(riskCateName) !== -1) {
              count = count + 1;
            }
          }
        });
        dataList.push(count);
      });
      console.log('dataList', dataList);
      return dataList;
    }
  },
  watch: {
    data () {
      this.updateChart();
    }
  },
  mounted () {
    const _this = this;
    _this.initXAxis();
    _this.queryGjbjcsTj();
    // 图表自适应宽度
    const gRize = function () {
      _this.resizeChart();
    };
    window.addEventListener('resize', gRize.bind(this), false);
  },
  destroyed () {
    this.chartLx1.dispose();
    this.chartLx2.dispose();
    this.chartLx3.dispose();
  }
};
</script>

<style lang="less" scoped>
.chart {
  width: 100%;
  height: 500px;
  background: #242C3F;
  border-radius: 20px 20px 20px 20px;
}
</style>
