<!--
 * @Author: zxf
 * @Date: 2023-10-16 15:19:15
 * @LastEditors: zxf
 * @LastEditTime: 2023-10-16 19:55:55
 * @Description: file content
-->
<template>
  <div class="u-ydbj-contains">
    <div class="u-ydbj-zc">
      <span class="u-ydbj-zc_num">{{ xfsbMonth.dwzs }}</span>
      <span class="u-ydbj-zc_label">监控单位数</span>
    </div>
    <div class="u-ydbj-circle">
      <div class="u-ydbj-wb u-ydbj-circle_item">
        <div style="margin-left: 6px;">
          <img
            id="todoy"
            src="/images/monitor/bj.svg"
          >
          <span class="u-ydbj-circle__label">设备总量</span>
          <span class="u-ydbj-circle__num">{{ xfsbMonth.sbzs }}</span>
        </div>
      </div>
      <div class="u-ydbj-zs u-ydbj-circle_item">
        <div style="margin-left: 6px;">
          <img
            id="todoy"
            src="/images/monitor/tj_xfs.svg"
          >
          <span class="u-ydbj-circle__label">消防水设备</span>
          <span class="u-ydbj-circle__num">{{ xfsbMonth.xfs }}</span>
        </div>
      </div>
      <div class="u-ydbj-ldcs u-ydbj-circle_item">
        <div style="margin-left: 6px;">
          <img
            id="todoy"
            src="/images/monitor/tj_dqhz.svg"
          >
          <span class="u-ydbj-circle__label">电气火灾设备</span>
          <span class="u-ydbj-circle__num">{{ xfsbMonth.dqhz }}</span>
        </div>
      </div>
      <div class="u-ydbj-ldcs u-ydbj-circle_item">
        <div style="margin-left: 6px;">
          <img
            id="todoy"
            src="/images/monitor/tj_hzbj.svg"
          >
          <span class="u-ydbj-circle__label">火灾报警设备</span>
          <span class="u-ydbj-circle__num">{{ xfsbMonth.hzbj }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Ydbjl',
  props: {
    xfsbMonth: {
      type: Object,
      default: () => { return null; }
    }
  }
};
</script>

<style scoped lang="scss">
.u-ydbj-contains{
  height: 140px;
  margin-left: 10px;
  margin-top: 20px;
  background: url(/images/monitor/ydbj.png);
  background-size: 100% 100%;
}

.u-ydbj-zc{
  position: relative;
  top: 40px;
  left: 8%;
  width: 72px;
  text-align: center;

  &_num{
    display: block;
    padding-left: 2px;
    font-family: PangMenZhengDao;
    font-size: 22px;
    letter-spacing: 0;
    color: #FFF;
  }

  &_label{
    display: block;
    height: 20px;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0;
    color: #55FAFF;
    text-align: center;

  }
}

.u-ydbj-circle{
  display: flex;
  position: relative;
  left: 30%;
  flex-direction: column;
  margin-top: -30px;
  img{
   width: 14px;
    height: 14px;
    margin-right: 14px;
  }

  &__label{
    width: 28px;
    height: 20px;
    margin-right: 41px;
    font-family: AlibabaPuHuiTi_2_55_Regular;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    color: #FFF;
  }

  &__num{
    width: 25px;
    height: 20px;
    font-family: PangMenZhengDao;
    font-size: 18px;
    letter-spacing: 0;
    color: #3DFBFF;
  }

  &_item{
    margin: 0 0 12px 10px;
  }
}

</style>
