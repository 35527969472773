<template>
  <div class="monitor_screen-chart">
    <div
      :class="introduction.ydbjl.style"
      class="monitor_schart monitor_spra-wrap"
      style="height: 210px;"
    >
      <!-- <sub-title
        inner-text="今日报警"
        style="margin-top: 25px"
      />
      <today-fire :hjxx-info="hjxxInfo" /> -->
      <sub-title
        inner-text="消防设备信息"
        style="margin-top: 25px;"
      />
      <!-- <div
        v-if="false"
        class="dev-yjcg"
        @click="queryPath()"
      >
        <img
          class="dev-cg-img"
          src="/assets/logo.png"
        >
        <span>智慧消防数字平台</span>
      </div> -->

      <!-- <ydbjl :xfsb-month="xfsbMonth" /> -->
      <xtqk :xfsb-month="xfsbMonth" />
    </div>

    <div
      :class="introduction.jrsbzt.style"
      class="monitor_schart monitor_spra-wrap"
      style="height: 220px;"
    >
      <!-- <div class="monitor_schart-header u-font-PFSC"> -->
      <sub-title inner-text="今日设备报警情况" />
      <!-- </div> -->

      <div
        style="display: flex;align-items: center;height: 100%;"
      >
        <div
          ref="grahpXfsb"
          :style="'height: 100%;width:50%'"
        />
        <div class="dev-o-rmsb__pie-extra">
          <div
            v-for="(item,index) in chartOption.series[0].data"
            :key="`pzhd__legend-${index+1}`"
            class="dev-o-rmsb__legend dev-o-rmsb__legend--chart"
          >
            <span
              class="dev-o-rmsb__legend-color"
              :style="'background: '+chartOption.color[index]"
            />
            <span class="dev-o-rmsb__legend-name"> {{ item.name }}</span>
            <span class="dev-o-rmsb__legend-value"> {{ item.value }}</span>
            <!-- <span class="dev-o-rmsb__legend-value"> {{ pieTotalValue==0?0:((item.value / pieTotalValue) * 100).toFixed(2) }}% </span> -->
          </div>
        </div>
      </div>
    </div>

    <div
      class="monitor_schart monitor_spra-wrap"
      style="height: 220px;"
    >
      <!-- <div class="monitor_schart-header u-font-PFSC"> -->
      <sub-title inner-text="灭火能力保障" />
      <div>
        <!--        <div-->
        <!--          ref="graphGwyl"-->
        <!--          :style="'height: 100%;width:100%'"-->
        <!--        />-->
        <Xfsc />
      </div>
    </div>

    <div
      :class="introduction.bjtj.style"
      class="monitor_schart monitor_study-wrap"
    >
      <!-- <div class="monitor_schart-header u-font-PFSC"> -->
      <sub-title inner-text="近6个月报警统计" />
      <!-- </div> -->

      <div
        style="height: 280px;"
      >
        <div
          ref="graphYzjb"
          :style="'height: 100%;width:100%'"
        />
      </div>
    </div>
    <div
      :class="introduction.xfsbsl.style"
      class="monitor_schart monitor_srobot-wrap"
      style="display: none;"
    >
      <div class="monitor_schart-header u-font-PFSC">
        <sub-title inner-text="消防设备数量" />
      </div>
      <div
        class="monitor_echart"
        style="height: 300px;"
      >
        <div
          ref="graphXfsbChart"
          :style="'height: 100%;width:100%'"
        />
      </div>
    </div>
    <DqhzIframe
      ref="Iframe"
      v-loading="loading"
      :url="path"
      :iframe-visible="iframeVisible"
    />
  </div>
</template>

<script>
import SubTitle from '../components/SubTitle2.vue';
// import TodayFire from './TodayFire.vue';
import xtqk from './xtqk.vue';
import { monitorService } from '../monitor.service';
import introduceJs from '@/features/introuduce/index.vue';
import EventBus from '@/event-bus';
import DqhzIframe from '../detail/dqhzFrameBox.vue';
import { loopShowTooltip } from '../tooltip-auto-show-vue.js';
import Xfsc from './xfsc.vue';
export default {
  name: 'ChartBox',
  components: {
    SubTitle,
    // TodayFire, // 今日火警
    xtqk,
    DqhzIframe,
    Xfsc
  },
  props: {
    dwList: {
      type: Array,
      default: () => {
        return [];
      }
    },
    activeMonitoringRoom: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      grahpXfsTimmer: null,
      loading: false,
      path: '',
      iframeVisible: false,
      // 火警信息
      hjxxInfo: {},
      // 月度消息
      xfsbMonth: {},
      // 近6个月报警柱状图
      graphYzjb: null,
      // 月度监控报告
      reportList: null,
      // 消防设备柱状图
      graphXfsbChart: null,
      // 消防设备 当日统计
      xfsbToday: {},
      sbzt: null, // 今日设备状态
      sbPieChartData: [
        // { value: 0, name: '正常' },
        { value: 0, name: '报警' },
        { value: 0, name: '故障' },
        { value: 0, name: '屏蔽' }
      ],
      // 当日设备状态饼状图
      grahpXfsb: null,
      introduction: {
        jrhj: {
          style: []
        },
        ydbjl: {
          style: []
        },
        ydjkbg: {
          style: []
        },
        bjtj: {
          style: []
        },
        xfsbsl: {
          style: []
        },
        jrsbzt: {
          style: []
        }
      }
    };
  },
  computed: {
    // 饼图的总数
    pieTotalValue () {
      const total = this.sbPieChartData.reduce((acc, item) => acc + item.value, 0);
      console.log('total', total);
      return total;
    },
    chartOption () {
      return {
        tooltip: {
            backgroundColor: 'rgba(0,0,0,0.5)',
            // borderColor: '#21f2c4',
            confine: true,
            trigger: 'item',
            textStyle: {
              color: '#fff', // 设置字体颜色
              fontSize: 13
            },
            formatter: function (params) {
              return `${params.name}: ${params.value} (${params.percent + '%'})`;
            }
        },
        legend: {
          show: false,
          top: '30%',
          left: '80%', // Align legend to the left for vertical display
          orient: 'vertical', // Set legend orientation to vertical
          textStyle: {
            color: '#ffffff' // Set legend text color to white
          }
        },
        color: ['#fd751f', '#f1e33d', '#0173fc'],
        series: [
          {
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 12,
                fontWeight: 'bold',
                color: '#fff'
              },
              itemStyle: {
                shadowColor: 'rgba(255, 255, 255, 0.9)',
                shadowBlur: 20
              }
            },
            labelLine: {
              show: false
            },
            data: this.sbPieChartData
          }
        ]
      };
    }
  },
  methods: {

    queryPath () {
      this.iframeLoad();
      this.path = 'https://iff-rpa.hongmeng.cloud/#/home';
      // this.path = 'http://localhost:18031/#/home';
      this.iframeVisible = true;
      // monitorService.queryPath()
      //   .then(rsp => {
      //     if (rsp.data && rsp.data.code === 200) {
      //       this.path = rsp.data.data;
      //       this.iframeVisible = true;
      //     } else {
      //       this.iframeVisible = false;
      //     }
      //   });
    },
        // 调用方法
      iframeLoad () {
      this.loading = true;
      const iframe = this.$refs.Iframe;
      // 兼容处理
      if (iframe.attachEvent) {
        // IE
        iframe.attachEvent('onload', () => {
          this.loading = false;
        });
      } else {
        // 非IE
        iframe.onload = () => {
          this.loading = false;
        };
      }
    },
    handleMessage (event) {
      // console.log('event.data111', event.data);

      if (event.data instanceof Boolean && !event.data) {
        this.loading = false;
        this.iframeVisible = event.data;
      } else if (event.data instanceof Boolean && event.data) {
        this.iframeVisible = true;
      } else {
        this.loading = false;
        this.iframeVisible = false;
      }
    },
    queryDqsbzt () {
      monitorService.queryDqsbzt().then(resp => {
        console.log('获取设备状态信息', resp.data);
        this.sbzt = resp.data;
      });
    },
    getHjxx () {
      monitorService.getHjxxByToday('')
        .then(rsp => {
          this.hjxxInfo = rsp.data;
        });
    },
    getXfsbByMonth () {
      monitorService.querySbqkTj()
        .then(rsp => {
          this.xfsbMonth = rsp.data;
        })
        .catch(err => {
          console.error('获取 月度设备状态信息错误！', err);
        });
    },
    initGraphYzjb () {
      this.graphYzjb = this.$echarts.init(this.$refs.graphYzjb);
      const option = {
        animationDuration: 3000,
        grid: {
          left: '20%',
          top: '10%'
        },
        legend: {
          right: 10,
          top: 0,
          textStyle: { color: '#ffffff', fontSize: 12 },
          itemStyle: { borderType: 'dashed' }
        },
        tooltip: {
            trigger: 'axis',
            backgroundColor: 'rgba(0,0,0,0)', // 设置背景色为透明
            textStyle: {
                color: '#fff' // 设置字体颜色为红色
            }
        },
        dataset: {
          source: [

          ]
        },
        xAxis: {
          type: 'category'
          // boundaryGap: false
        },
        yAxis: {
          axisLabel: { show: true },
          splitLine: { lineStyle: { color: '#016e9a', opacity: 0.5 } }
        },
        series: [
          {
            type: 'line',
            smooth: true,
            color: 'rgba(239, 228, 57, 0.8)',
            areaStyle: {
              // color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: 'rgba(239, 228, 57, 0.5)' }, // Start color with opacity
              //   { offset: 1, color: 'rgba(246, 189, 22, 0)' } // End color with opacity
              // ])
            },
            label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 }
          },
          {
            type: 'line',
            smooth: true,
            color: 'rgba(239, 142, 36, 0.8)',
            areaStyle: {
              // color: this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
              //   { offset: 0, color: 'rgba(239, 142, 36, 0.5)' }, // Start color with opacity
              //   { offset: 1, color: 'rgba(246, 189, 22, 0)' } // End color with opacity
              // ])
            },
            label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 }
          }
        ],
        color: ['#f6bd16', '#e8684a'],
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontSize: 12,
          color: '#fff'
        }
      };

      this.graphYzjb.setOption(option);
    },
    updateGraphYzjb () {
      monitorService.queryTotalHjxxByMonths()
        .then(rsp => {
          const data = [['月份', '报警', '故障']];
          rsp.data.forEach(el => {
            data.push([`${el.days.split('-')}月份`, el.bjsl, el.gzsl]);
          });
          // const data = [['月份', '报警', '故障'],
          //   ['6月份', 0, 2],
          //   ['7月份', 0, 0],
          //   ['8月份', 0, 0],
          //   ['9月份', 0, 2],
          //   ['10月份', 2, 5],
          //   ['11月份', 2, 2]];
          const opt = this.graphYzjb.getOption();
          opt.dataset.source = data;
          this.graphYzjb.setOption({
            dataset: {
              source: data
            }
          });
          // tools.autoHover(this.graphYzjb, opt, data.length, 3000);
        });
    },
    initGraphXfsbChart () {
      this.graphXfsbChart = this.$echarts.init(this.$refs.graphXfsbChart);
      const option = {
        animationDuration: 3000,
        legend: {
          left: 30,
          top: 10,
          textStyle: { color: '#ffffff', fontSize: 12 },
          itemStyle: { borderType: 'dashed' }
        },
        tooltip: {},
        dataset: {
          source: [

          ]
        },
        xAxis: {
          type: 'category',
          // boundaryGap: false
          axisLabel: {
            rotate: 90,
            fontSize: 10
          }
        },
        yAxis: {
          axisLabel: { show: false },
          splitLine: { lineStyle: { color: '#016e9a', opacity: 0.5 } }
        },
        series: [
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } },
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } },
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } },
          { type: 'bar', label: { show: true, position: 'top', color: '#ffffff', fontSize: 12 } }
        ],
        color: ['#10b6ff', '#f6bd16', '#e8684a', '#ff9baf'],
        textStyle: {
          fontFamily: 'Microsoft YaHei',
          fontSize: 12,
          color: '#fff'
        }
      };
      this.graphXfsbChart.setOption(option);
    },
    updateGraphXfsbChart () {
      const data = [['联网单位', '正常', '报警', '故障', '屏蔽']];
      this.dwList.forEach((item, index) => {
        const row = [item.name, item.health, item.error, item.standby, item.qt];
        data.push(row);
      });
      const opt = this.graphXfsbChart.getOption();
      opt.dataset.source = data;
      this.graphXfsbChart.setOption({
        dataset: {
          source: data
        }
      });
    },
    initGrahpXfsb () {
      this.grahpXfsb = this.$echarts.init(this.$refs.grahpXfsb);
      this.grahpXfsb.setOption(this.chartOption);
    },
    updateGrahpXfsb () {
      this.grahpXfsb.clear();
      this.grahpXfsb.setOption(this.chartOption);
      this.loopTooltip(this.grahpXfsb, this.chartOption);
    },
    // 轮播tooltip
    loopTooltip (graph, option) {
            // 调用轮播的方法
            loopShowTooltip(graph, option, {
                isShowTip: 'hide', // 轮播时是否显示tooltip: show/hide
                interval: 2000, // 轮播间隔时间
                loopSeries: true // 是否开启轮播循环
                // shunXu: "daoXu", // 显示顺序
                // loopSeries: boolean类型，默认为false。true表示循环所有series的tooltip；false则显示指定seriesIndex的tooltip。
                // seriesIndex: 默认为0，指定某个系列（option中的series索引）循环显示tooltip，当loopSeries为true时，从seriesIndex系列开始执行。
            });
      },
    /**
     * 获取消防设备数据（当天）
     */
    getXfsbByToday () {
      monitorService.getXfsbByToday()
        .then(rsp => {
          this.xfsbToday = rsp.data;
          this.sbPieChartData = [];
          this.sbPieChartData.push(
            // { value: rsp.data.zc, name: '正常' },
            { value: rsp.data.bj, name: '报警' },
            { value: rsp.data.gz, name: '故障' },
             { value: rsp.data.qt, name: '屏蔽' }
             );
          this.updateGrahpXfsb();
        })
        .catch(err => {
          console.error('获取设备状态信息错误！', err);
        });
    },
    queryReport () {
      monitorService.queryReport()
        .then(rsp => {
          this.reportList = rsp.data;
        })
        .catch(err => {
          console.error('获取联网单位信息错误！', err);
        });
    },
    resizeGrapha () {
      this.graphYzjb.resize();
      // this.graphXfsbChart.resize();
      this.grahpXfsb.resize();
    }
  },
  watch: {
    dwList () {
      this.updateGraphXfsbChart();
    },
    activeMonitoringRoom () {
      this.resizeGrapha();
    }
  },
  mounted () {
    const _this = this;
    this.clock = setInterval(function () {
      _this.getHjxx();
    }, 30 * 60 * 1000);
    this.getHjxx();
    this.getXfsbByMonth();
    this.initGraphYzjb();
    this.updateGraphYzjb();
    this.initGraphXfsbChart();
    this.initGrahpXfsb();
    this.getXfsbByToday();
    this.queryReport();

    // 图表自适应宽度
    const gRize = function () {
      this.resizeGrapha();
    };
    window.addEventListener('resize', gRize.bind(this), false);

    // 注册监听框架传来的数据
    this.$nextTick(() => {
        // 在外部 Vue 的 window 上添加 postMessage 的监听，并且绑定处理函数 handleMessage
        // ifream 传递过来的数据 关闭ifream
        window.addEventListener('message', this.handleMessage);
      });
  },
  created () {
    const _this = this;
    EventBus.$on('introductionZt_Right_start', function (data) {
      introduceJs.activeElement(_this.introduction, data);
    });
    EventBus.$on('introductionZt_Right_stop', function (data) {
      introduceJs.clearStyle(_this.introduction);
    });
  },

  destroyed () {
    clearInterval(this.clock);
    this.graphYzjb.dispose();
    this.graphXfsbChart.dispose();
    this.grahpXfsb.dispose();
    window.removeEventListener('message', this.handleMessage);
  }
};
</script>

<style lang="scss" scoped>
@import '../monitor.scss';
@import '../../introuduce/introduce.css';

.dev-yjcg{
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: auto;
    position: absolute;
    right: 5px;
    top: 16px;
    border: 1px solid #03A9F4;
    text-align: center;
    line-height: 32px;

    border-radius: 4px;
    font-family: AlibabaPuHuiTi_2_85_Bold;
    font-weight: 700;
    font-size: 14px;
    background: #002F72;
    -webkit-box-shadow: inset 0 0 8px 0 #0066ff80;
    box-shadow: inset 0 0 8px 0 #00D1FE;
    color: #ffff;
    z-index: 999;

}
.dev-cg-img{
  width: 24px;
  height: 24px;
  margin: 0 5px;
}
</style>
