import { render, staticRenderFns } from "./newRightBox.vue?vue&type=template&id=00df002c&scoped=true"
import script from "./newRightBox.vue?vue&type=script&lang=js"
export * from "./newRightBox.vue?vue&type=script&lang=js"
import style0 from "./newRightBox.vue?vue&type=style&index=0&id=00df002c&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "00df002c",
  null
  
)

export default component.exports