<template>
  <div
    class="monitor_fire"
  >
    <div
      class="monitor_fire-body"
    >
      <div
        class="hj"
      >
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_sbzs.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #00CCFF;;font-size: 16px;"
          >
            设备总数
          </div>
          <div
            class="monitor_fire-right-warn"
            style="color: #00CCFF;;font-size: 24px;"
          >
            {{ tjxxInfo.sbzs === null?'0': tjxxInfo.sbzs }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="monitor_fire-body"
      @click="handlBjzt('1', '2')"
    >
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_zshj.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #E1574D;;font-size: 16px;"
          >
            真实火警
          </div>
          <div
            class="monitor_fire-right-warn"
            style="color: #E1574D;;font-size: 24px;"
          >
            {{ tjxxInfo.zshj === null?'0': tjxxInfo.zshj }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="monitor_fire-body"
      @click="handlBjzt('1', '')"
    >
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_hj.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #E1564D;font-size: 16px;"
          >
            火警
          </div>
          <div
            class="monitor_fire-right-warn"
            style="color: #E1564D;font-size: 24px;"
          >
            {{ tjxxInfo.jthj === null?'0': tjxxInfo.jthj }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="monitor_fire-body"
      @click="handlBjzt('1', null)"
    >
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_dqr.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #F1DD55;font-size: 16px;"
          >
            待确认
          </div>
          <div
            class="monitor_fire-right-num"
            style="color: #F1DD55;font-size: 24px;"
          >
            {{ tjxxInfo.dqr === null?'0': tjxxInfo.dqr }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="monitor_fire-body"
      @click="handlBjzt('1', '1')"
    >
      <div class="hj">
        <div class="monitor_fire-left">
          <img src="/monitor/images/top/t_wb2.png">
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: #BDCAE1;font-size: 16px;"
          >
            误报
          </div>
          <div
            class="monitor_fire-right-num"
            style="color: #BDCAE1;font-size: 24px;"
          >
            {{ tjxxInfo.wb === null?'0': tjxxInfo.wb }}
          </div>
        </div>
      </div>
    </div>
    <div
      class="monitor_fire-body"
      @click="handlBjzt('2', '')"
    >
      <div class="hj">
        <div
          class="monitor_fire-left"
        >
          <img
            src="/monitor/images/top/t_gzzs.png"
          >
        </div>
        <div class="monitor_fire-right">
          <div
            class="monitor_fire-right-title"
            style="color: rgb(221 140 6);;font-size: 16px;"
          >
            故障数
          </div>
          <div
            class="monitor_fire-right-num"
            style="color: rgb(221 140 6);;font-size: 24px;"
          >
            {{ tjxxInfo.gzzs === null?'0': tjxxInfo.gzzs }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { monitorService } from '../monitor.service';
import EventBus from '@/event-bus';
export default {
  name: 'DetailTopBoxT',
  components: {
  },
  props: {
    // 联网单位
    code: {
      type: String,
      default: 'null'
    }
  },
  data () {
    return {
      // loading: false,
      // path: '',
      // iframeVisible: false,
      clock: null,
      // 火警信息
      tjxxInfo: {
        gzzs: '0',
        wb: '0',
        dqr: '0',
        zshj: '0',
        jthj: '0'
      }
    };
  },
  methods: {
    queryTjxxs () {
      monitorService.queryTjxxs(this.code)
        .then(rsp => {
          this.tjxxInfo = rsp.data;
        });
    },

    handlBjzt (xxlx, xxqr) {
      console.log('bjzt', xxlx, xxqr);
      EventBus.$emit('topBjzt', { xxlx: xxlx, xxqr: xxqr });
    }
    // 调用方法
    // iframeLoad () {
    //   this.loading = true;
    //   const iframe = this.$refs.Iframe;
    //   // 兼容处理
    //   if (iframe.attachEvent) {
    //     // IE
    //     iframe.attachEvent('onload', () => {
    //       this.loading = false;
    //     });
    //   } else {
    //     // 非IE
    //     iframe.onload = () => {
    //       this.loading = false;
    //     };
    //   }
    // },
    // handleMessage (event) {
    //   if (event) {
    //     this.loading = false;
    //     this.iframeVisible = event.data;
    //   } else {
    //     this.iframeVisible = true;
    //   }
    // },
    // queryPath () {
    //   this.iframeLoad();
    //   monitorService.queryPath()
    //     .then(rsp => {
    //       if (rsp.data && rsp.data.code === 200) {
    //         this.path = rsp.data.data;
    //         this.iframeVisible = true;
    //       } else {
    //         this.iframeVisible = false;
    //       }
    //     });
    // }
  },
  watch: {
    code (newVal, oldVal) {
      console.log('code', newVal, oldVal);
      const _this = this;
        this.queryTjxxs();
        this.clock = setInterval(function () {
          if (newVal) {
             _this.queryTjxxs();
          } else {
            clearInterval(_this.clock);
          }
        }, 10 * 1000);
    }
  },
  mounted () {
      // 注册监听框架传来的数据
      // this.$nextTick(() => {
      //   // 在外部 Vue 的 window 上添加 postMessage 的监听，并且绑定处理函数 handleMessage
      //   // ifream 传递过来的数据 关闭ifream
      //   window.addEventListener('message', this.handleMessage);
      // });
  },
  created () {
    const _this = this;
    this.queryTjxxs();
    this.clock = setInterval(function () {
      if (_this.code) {
           _this.queryTjxxs();
        } else {
          clearInterval(_this.clock);
      }
    }, 10 * 1000);
  },
  destroyed () {
    clearInterval(this.clock);
     // 注意移除监听！
    //  window.removeEventListener('message', this.handleMessage);
  }
};
</script>

  <style scoped lang="scss">
  .monitor_fire {
    height: 120px;
    // padding: 23px 0 13px 80px;
    padding: 23px 10px 0 10px;

    // background: url('/monitor/images/fireTitle.png');
    // background-size: cover;
  }

  .monitor_fire-body{
    display: flex;
    float: left;
    width: 15%;
    z-index: 1;
    // padding: 0 0 0 0;
  }
  .hj{
    display: flex;
    float: left;
    background-repeat:no-repeat;
    z-index:-1;
    width: 180px;
    height: 70px;
    background-image: url('/monitor/images/top/t_bj.png');
  }
  .monitor_fire-left{
    // margin: auto;
    padding:14px;

  }

  .monitor_fire-right {
    margin: 12px 0 0 10px;

    &-title{
      font-family: MicrosoftYaHei;
      font-size: 14px;
      font-stretch: normal;
      font-weight: normal;
    //   color: #d6dee7;
    }

    &-num {
      margin: 8px 0 0 0;
      font-family: Digital-7Mono;
      font-size: 20px;
      font-stretch: normal;
      font-weight: normal;
      letter-spacing: 0;
      color: #278dff;
    }

    &-warn {
      margin: 8px 0 0 0;
      font-family: Digital-7Mono;
      font-size: 20px;
      font-stretch: normal;
      font-weight: normal;
      letter-spacing: 0;
      color: #e4584e;
    }
  }

  </style>
