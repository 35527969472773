<!--
 * @Author: zxf
 * @Date: 2024-01-02 17:16:29
 * @LastEditors: zry
 * @LastEditTime: 2024-01-05 15:40:00
 * @Description: 电气设备状态
-->
<template>
  <!-- <div class="div-body"> -->
  <div
    id="jzsbzt"
    ref="jzsbzt"
    style="width: 90%;height: 100%"
  />
  <!-- </div> -->
</template>

<script>
import { monitorService } from '../monitor.service';
// import tools from '../tools.js';
import { loopShowTooltip } from '../tooltip-auto-show-vue.js';
export default {
    name: 'Jzsbzt',
    components: {
        // Your component's components go here
    },
    // Your component's logic goes here
    data () {
        return {
            option: null,
            clock: null,
            graph: null,
            dataList: [
                    { value: 40, name: '正常' },
                    { value: 38, name: '漏电' },
                    { value: 32, name: '过温' },
                    { value: 30, name: '过压' },
                    { value: 28, name: '过流' },
                    { value: 26, name: '过载' }
            ]
        };
    },
    methods: {
        queryDqhztj () {
            monitorService.queryDqhztj()
                .then(rsp => {
                    this.dataList = [
                        { value: rsp.data.zc, name: '正常' },
                        { value: rsp.data.ld, name: '漏电' },
                        { value: rsp.data.gw, name: '过温' },
                        { value: rsp.data.gy, name: '过压' },
                        { value: rsp.data.gl, name: '过流' },
                        { value: rsp.data.gz, name: '过载' }
                    ];
                    this.initEchart();
            }).catch(err => {
                console.log('查询失败！', err);
            });
        },
        initEchart () {
            this.graph = this.$echarts.init(this.$refs.jzsbzt);
            this.option = {
            legend: {
                right: '2%',
                textStyle: {
                 color: '#fff'
                },
                orient: 'vertical',
                x: 'right',
                y: 'center'
            },
            tooltip: {
                trigger: 'item',
                backgroundColor: 'rgba(0,0,0,0.4)', // 设置背景色为透明
                textStyle: {
                    color: '#fff' // 设置字体颜色为红色
                },
                formatter: '{b} : {c} ({d}%)'
            },
            series: [
                {
                name: '电气设备状态',
                type: 'pie',
                radius: [15, 75],
                center: ['40%', '50%'],
                roseType: 'area',
                label: {
                    color: '#fff'
                },
                emphasis: {
                    itemStyle: {
                        borderRadius: 6,
                        shadowColor: 'rgba(255, 255, 255, 0.9)',
                        shadowBlur: 20
                    }
                },
                itemStyle: {
                    borderRadius: 6
                },
                data: this.dataList
                }
            ]
            };
            this.graph.setOption(this.option);
        },
        // 轮播tooltip
        loopTooltip (graph, option) {
            // 调用轮播的方法
            loopShowTooltip(graph, option, {
                interval: 2000, // 轮播间隔时间
                loopSeries: true // 是否开启轮播循环
                // shunXu: "daoXu", // 显示顺序
                // loopSeries: boolean类型，默认为false。true表示循环所有series的tooltip；false则显示指定seriesIndex的tooltip。
                // seriesIndex: 默认为0，指定某个系列（option中的series索引）循环显示tooltip，当loopSeries为true时，从seriesIndex系列开始执行。
            });
        }

    },
    created () {
        const _this = this;
        this.queryDqhztj();
        this.clock = setInterval(function () {
        _this.queryDqhztj();
        }, 1 * 18 * 10 * 1000);
    },
    mounted () {
        const _this = this;
        this.initEchart();
        const gRize = function () {
        _this.graph.resize();
        };
        window.addEventListener('resize', gRize.bind(this), false);
        this.loopTooltip(this.graph, this.option);
    },
    destroyed () {
        clearInterval(this.clock);
        window.removeEventListener('resize', function () {
                this.graph.resize();
            });
    }
};
</script>

<style scoped>

</style>
