<template>
  <div
    v-loading="isLoading"
    :element-loading-text="progressText"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)"
    class="canvasContainer"
  >
    <div
      ref="canvasContainer"
      class="canvasContainer"
    />
    <div
      v-if="progress !== null && progress!=-1"
      class="progress"
    >
      <el-progress :percentage="progress" />
    </div>
    <div
      v-if="error !== null"
      class="error"
      :title="error"
    >
      <el-alert
        :title="error"
        type="error"
        show-icon
      />
    </div>
  </div>
</template>

<script>
import { DxfViewer } from 'dxf-viewer';
import * as three from 'three';
import DxfViewerWorker from '../../cad/wzdx/DxfViewerWorker';
import { DxfControl } from './DxfControl';

/** Events: all DxfViewer supported events (see DxfViewer.Subscribe()), prefixed with "dxf-". */
export default {
  name: 'DxfViewer',

  props: {
    // dxfUrl: {
    //   type: String,
    //   default: null
    // },
    // jzwCadList: {
    //   type: Array,
    //   default: null
    // },
    aJzwCad: {
      type: Object,
      default: null
    },
    /** List of font URLs. Files should have TTF format. Fonts are used in the specified order,
         * each one is checked until necessary glyph is found. Text is not rendered if fonts are not
         * specified.
         */
    fonts: {
      type: Array,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: true
    },
    showBjzt: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default () {
        return {
          clearColor: new three.Color('#08193F'),
          autoResize: true,
          colorCorrection: true
        };
      }
    }
  },

  data () {
    return {
      isLoading: false,
      progress: null,
      progressText: null,
      curProgressPhase: null,
      error: null,
      initDownloadApi: this.$config.fileApi.initDownload,
      downloadApi: this.$config.fileApi.download,
      dxfUrl: null
    };
  },

  methods: {
    async checkFonts () {
      if (!window.isSupportFontFamily('HanaMinA')) {
        const h = this.$createElement;
        this.$confirm('提示', {
          title: '提示',
          message: h('div', [
            h('p', '检测到系统未安装CAD图形显示字体，图形显示可能异常')
          ]),
          confirmButtonText: '前往安装',
          cancelButtonText: '取消',
          type: 'warning',
          callback: (action) => {
            if (action === 'confirm') {
              window.location.href = process.env.BASE_URL + 'fonts.html';
            }
          }
        });
      }
    },
    async Load (url) {
      this.isLoading = true;
      this.error = null;
      try {
        await this.checkFonts();
        await this.dxfViewer.Load({
          url,
          fonts: this.fonts,
          progressCbk: this._OnProgress.bind(this),
          workerFactory: DxfViewerWorker
        });
        // 初始化cad消防设备图标
        const mnhj = sessionStorage.getItem('mnhj');
        var isFakeFire = false;
        if (mnhj && mnhj !== '') {
          isFakeFire = true;
        }
        await this.dxfControl.loadXfsb({ cadUuid: this.aJzwCad.uuid, jzlc: this.aJzwCad.lcbm }, isFakeFire);
        this.$emit('updateXfsb', this.dxfControl.getXfsbs());
      } catch (error) {
        console.warn(error);
        this.error = error.toString();
      } finally {
        this.isLoading = false;
        this.progressText = null;
        this.progress = null;
        this.curProgressPhase = null;
      }
    },

    GetDxfControls () {
      return this.dxfControl;
    },

    /** @return {DxfViewer} */
    GetViewer () {
      return this.dxfViewer;
    },

    _OnProgress (phase, size, totalSize) {
      if (phase !== this.curProgressPhase) {
        switch (phase) {
          case 'font':
            this.progressText = 'Fetching fonts...';
            break;
          case 'fetch':
            this.progressText = 'Fetching file...';
            break;
          case 'parse':
            this.progressText = 'Parsing file...';
            break;
          case 'prepare':
            this.progressText = 'Preparing rendering data...';
            break;
        }
        this.curProgressPhase = phase;
      }
      if (totalSize === null) {
        this.progress = -1;
      } else {
        this.progress = size / totalSize * 100;
      }
    },
    /**
     * @description: 初始化下载
     * @param {*} fileId
     * @return {*}
     */
    initDownload (fileId) {
      return this.$axios.get(`${this.initDownloadApi}/${fileId}`);
    },
    /**
     * @description: 获取文件下载路径
     * @param {*} file
     * @return {*}
     */
    handlePreview (file) {
      const _this = this;
      const url = sessionStorage.getItem(file);
      if (url) {
        _this.dxfUrl = url;
        _this.Load(_this.dxfUrl);
        return;
      }
      this.initDownload(file)
        .then((rsp) => {
          const filePath = rsp.data.data;
          _this.$axios.get(`${_this.downloadApi}/${filePath}`)
            .then((res) => {
              // 转换blob类型的数据
              const blob = new Blob([res.data], { type: 'application/x-autocad' });
              // 创建blob链接
              const path = URL.createObjectURL(blob);
              _this.dxfUrl = path;
              _this.Load(_this.dxfUrl);
              sessionStorage.setItem(file, _this.dxfUrl);
            })
            .catch((err) => {
              console.error('下载文件失败！', err);
            });
        })
        .catch((err) => {
          console.error('初始化下载文件失败！', err);
        });
    }
  },

  watch: {
    async aJzwCad (newVal) {
      if (newVal) {
        this.isLoading = true;
        this.handlePreview(this.aJzwCad.cadUrl);
      } else {
        this.dxfViewer.Clear();
        this.error = null;
        this.isLoading = false;
        this.progress = null;
      }
    }

  },

  mounted () {
    this.dxfViewer = new DxfViewer(this.$refs.canvasContainer, this.options);
    const Subscribe = eventName => {
      this.dxfViewer.Subscribe(eventName, e => this.$emit('dxf-' + eventName, e));
    };
    for (const eventName of ['loaded', 'cleared', 'destroyed', 'resized', 'pointerdown',
      'pointerup', 'viewChanged', 'message']) {
      Subscribe(eventName);
    }
    // 创建cad编辑工具
    this.dxfControl = new DxfControl(this.dxfViewer, this.showBjzt);
    this.dxfControl.setReadOnly(this.readOnly);
  },

  destroyed () {
    this.dxfViewer.Destroy();
    this.dxfViewer = null;
  }
};
</script>

<style lang="scss" scoped>

.canvasContainer {
  position: relative;
  width: 100%;
  min-width: 100px;
  height: 100%;
  min-height: 100px;

  .progress {
    position: absolute;
    z-index: 20;
    width: 90%;
    margin: 20px 5%;

    .progressText {
      margin: 10px 20px;
      font-size: 14px;
      color: #262d33;
      text-align: center;
    }
  }

  .error {
    position: absolute;
    z-index: 20;
    width: 100%;
    height: 100%;
    padding: 30px;

    img {
      width: 24px;
      height: 24px;
      margin: 4px;
      vertical-align: middle;
    }
  }

}

</style>
